import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { PraccName } from 'containers/billing/StripePaymentPage';
import LogoImage from '../../containers/app/img/betalogo.png';
import { DrawerHeader } from '.';
import { useHistory, useLocation } from 'react-router-dom';
import { RiSwordLine as SwordsIcon } from 'react-icons/ri';
import { SlCamrecorder as VideoCamIcon } from 'react-icons/sl';
import { SiProgress as OptimizeIcon } from 'react-icons/si';
import { FaRegPlayCircle as ReplayIcon } from 'react-icons/fa';
import { AiOutlineAim as DeathmatchIcon } from 'react-icons/ai';
import { BsShieldLock, BsDiscord } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import LoggedInUserButton from './menu/logged-in-user-button';
import { IoNotifications } from 'react-icons/io5';
import { MdChat } from 'react-icons/md';
import { IoMdSettings, IoIosAddCircle } from 'react-icons/io';
import TeamSelectionButton from '../../containers/app/menu/team-selection-button';
import {
    addNotification,
    loadProfile,
    openChat,
    openMsgs,
    resendConfirmationEmail,
    selectTeam,
    setEmailUnconfirmedHidden,
    setRatingIsOpenHidden,
    setUnreadMsgs,
    showNotifySnack,
    updateIsLoadingNotifications,
    updateProfile,
    updateTeamProfile,
} from './reducer';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slide from '@mui/material/Slide';
import NotificationDrawer from './NotificationDrawer';
import ChatDrawer from './chat/chat-drawer';
import useUnreadChats from './chat/useUnreadChats';
import { ButtonLink } from 'components/layout/buttons';
import { Dns } from '@mui/icons-material';
import { GAME_ID_CSGO } from 'utils/games';
import UserGameButton from './menu/user-game-button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuWrapper: {
            display: 'block',
            backgroundColor: 'rgb(13, 71, 161)',
            position: 'fixed',
            zIndex: 10000,
            top: 0,
            '@media (min-width: 1025px)': {
                display: 'none',
            },
        },

        toolbar: theme.mixins.toolbar,
        activeLink: {
            backgroundColor: '#181D31',
            borderRadius: '10px',
        },
        logo: {
            zIndex: 10,
            position: 'relative',
            height: '74px',
            width: '77px',
            '@media (max-width: 1024px)': {
                height: '50px',
                width: '50px',
            },
        },
        listItem: {
            '& .MuiTypography-root': {
                fontSize: '1.1rem',
                fontWeight: 700,
            },
        },
        link: {
            color: 'white',
            padding: '0.3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            '&:hover': {
                backgroundColor: '#2c324a',
            },
        },
        notificationDrawer: {
            // marginTop: '3.5rem',
            position: 'fixed',
            zIndex: 90000,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(12, 17, 38)',
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            height: '92vh',
            overflowY: 'scroll',
            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
            width: '100%',
            '@media (min-width: 1025px)': {
                display: 'none',
            },
        },
        chatDrawer2: {
            position: 'absolute',
            height: '92vh',
            zIndex: 90000,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(12, 17, 38)',
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
            width: '100%',
            '@media (min-width: 1025px)': {
                display: 'none',
            },
        },
    })
);

function MobileMenu(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {
        isLoadingNotifications,
        handleCloseDrawer,
        notifications,
        profile,
        selectTeam,
        msgDrawerOpen,
        activeChats,
    } = props;

    const { unreadChatsCount, isLoading, hasLoadError } = useUnreadChats();

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    // Function to handle clicks outside of notification and chat drawers
    const handleOutsideClick = (event: MouseEvent) => {
        const notificationDrawer = document.getElementById('notificationDrawer');
        const chatDrawer = document.getElementById('chatDrawer');

        if (
            (notificationDrawer && !notificationDrawer.contains(event.target as Node)) ||
            (chatDrawer && !chatDrawer.contains(event.target as Node))
        ) {
            setShowNotification(false);
            openMsgs(false)(dispatch);
        }
    };

    // Add event listener for clicks outside of drawers
    // React.useEffect(() => {
    //     document.addEventListener('click', handleOutsideClick);
    //     return () => {
    //         document.removeEventListener('click', handleOutsideClick);
    //     };
    // }, []);

    const handleMenuItemClick = (route: string) => {
        openMsgs(false)(dispatch);
        setShowNotification(false);
        history.push(route);
    };

    const isLinkActive = (routes: string[]): boolean => {
        return routes.some((route) => location.pathname === route || location.pathname.startsWith(`${route}/`));
    };

    const handleShowNotification = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setOpen(false);
        openMsgs(false)(dispatch);
        setShowNotification(!showNotification);
    };

    const handleShowChat = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setOpen(false);
        setShowNotification(false);
        openMsgs(!msgDrawerOpen)(dispatch);
    };

    const containerRef = React.useRef<HTMLElement>(null);

    const hasTeam = profile?.Teams?.length > 0;

    const isCSGO = !profile || profile.GameId === GAME_ID_CSGO;

    return (
        <Box ref={containerRef}>
            <Box sx={{ height: '8vh' }}>
                <AppBar position="static" className={classes.menuWrapper}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2, gap: 1 }}
                            onClick={toggleDrawer(true)}
                        >
                            <img src={LogoImage} alt="PRACC.COM Logo" width={27} height={27} />
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="temporary"
                    open={open}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            backgroundColor: 'rgb(12, 17, 38)',
                            backgroundImage:
                                'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                        },
                    }}
                    onClose={toggleDrawer(false)}
                    anchor="left"
                >
                    <DrawerHeader
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '1rem 1rem 0 0.5rem',
                            width: '100%',
                            flexDirection: 'column',
                            backgroundColor: '#0d47a1',
                        }}
                        onClick={toggleDrawer(true)}
                    >
                        {open ? (
                            <img
                                src={LogoImage}
                                alt="PRACC.COM Logo"
                                className={classes.logo}
                                onClick={() => history.push(profile ? '/' : '/login')}
                            />
                        ) : (
                            <img
                                src={LogoImage}
                                alt="PRACC.COM Logo"
                                width={37}
                                height={37}
                                style={{ marginBottom: '1rem' }}
                            />
                        )}

                        {open && <PraccName style={{ textDecoration: 'none' }}>PRACC</PraccName>}
                    </DrawerHeader>
                    <Divider />
                    <Box
                        sx={{
                            flex: 1,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <List sx={{ width: '90%' }}>
                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                            setOpen(false);
                                            handleMenuItemClick('/play');
                                        }}
                                        className={isLinkActive(['/play']) ? classes.activeLink : ''}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <DeathmatchIcon size={25} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Play"
                                            className={classes.listItem}
                                            sx={{ opacity: 1 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                        handleMenuItemClick(profile ? '/search' : '/scrim-search');
                                    }}
                                    className={
                                        isLinkActive(['/search', '/groups', '/matches', '/stats'])
                                            ? classes.activeLink
                                            : ''
                                    }
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SwordsIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText primary="Scrims" className={classes.listItem} sx={{ opacity: 1 }} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                        handleMenuItemClick('/recorder');
                                    }}
                                    className={isLinkActive(['/recorder']) ? classes.activeLink : ''}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <VideoCamIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText primary="Recorder" className={classes.listItem} sx={{ opacity: 1 }} />
                                </ListItemButton>
                            </ListItem>

                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                            setOpen(false);
                                            handleMenuItemClick('/replay');
                                        }}
                                        className={isLinkActive(['/replay']) ? classes.activeLink : ''}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ReplayIcon size={25} />
                                        </ListItemIcon>
                                        <ListItemText primary="Replay" className={classes.listItem} sx={{ opacity: 1 }} />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                        handleMenuItemClick('/performance');
                                    }}
                                    className={isLinkActive(['/performance']) ? classes.activeLink : ''}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <OptimizeIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Performance"
                                        className={classes.listItem}
                                        sx={{ opacity: 1 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        
                        <Divider variant="middle" sx={{ width: '90%' }} />
                        {profile ? (
                            <>
                                <List sx={{ width: '90%' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            backgroundColor: showNotification ? '#181D31' : 'none',
                                            borderRadius: '10px',
                                        }}
                                        onClick={(event: any) => handleShowNotification(event)}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: 'initial',
                                                px: 0,
                                                borderRadius: '10px',
                                                height: 51,
                                                py: 0,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: 6,
                                                    height: '100%',
                                                    backgroundColor: showNotification ? '#f50057' : '',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                }}
                                            />
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: 3,
                                                    justifyContent: 'center',
                                                    ml: 1.6,
                                                    position: 'relative',
                                                }}
                                            >
                                                <IoNotifications size={25} />
                                                {profile?.NewNotifications > 0 && (
                                                    <Box
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            backgroundColor: '#f50057',
                                                            borderRadius: '100%',
                                                            position: 'absolute',
                                                            right: '-0.2rem',
                                                            top: '-0.2rem',
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Notifications"
                                                className={classes.listItem}
                                                sx={{ opacity: 1 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            backgroundColor: msgDrawerOpen ? '#181D31' : 'none',
                                            borderRadius: '10px',
                                        }}
                                        onClick={(event: any) => handleShowChat(event)}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: 'initial',
                                                px: 0,
                                                borderRadius: '10px',
                                                height: 51,
                                                py: 0,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: 6,
                                                    height: '100%',
                                                    backgroundColor: msgDrawerOpen ? '#f50057' : '',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                }}
                                            />
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: 3,
                                                    justifyContent: 'center',
                                                    ml: 1.6,
                                                    position: 'relative',
                                                }}
                                            >
                                                <MdChat size={25} />
                                                {unreadChatsCount > 0 && (
                                                    <Box
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            backgroundColor: '#f50057',
                                                            borderRadius: '100%',
                                                            position: 'absolute',
                                                            right: '-0.2rem',
                                                            top: '-0.2rem',
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Chats"
                                                className={classes.listItem}
                                                sx={{ opacity: 1 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>

                                <List sx={{ width: '90%', paddingTop: '0px' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            backgroundColor: '#181D31',
                                            width: 'auto',
                                            borderRadius: '10px',
                                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            paddingLeft: '1.25rem',
                                            paddingRight: '1.25rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: '#2c324a',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <LoggedInUserButton />
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>

                                {hasTeam ? (
                                    <List sx={{ width: '90%', paddingTop: '0px' }}>
                                        <ListItem
                                            disablePadding
                                            sx={{
                                                backgroundColor: '#181D31',
                                                width: 'auto',
                                                borderRadius: '10px',
                                                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                                paddingLeft: '1.25rem',
                                                paddingRight: '1.25rem',
                                                paddingTop: '0.3rem',
                                                paddingBottom: '0.5rem',
                                                height: 100,
                                                '&:hover': {
                                                    backgroundColor: '#2c324a',
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <TeamSelectionButton />

                                                <Box
                                                    style={{
                                                        backgroundColor: '#192841',
                                                        padding: '3px',
                                                        borderRadius: '10px',
                                                        marginTop: '1rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        hasTeam ? history.push('/team') : history.push('/team/create')
                                                    }
                                                >
                                                    <IoMdSettings size={20} />
                                                    <Typography
                                                        sx={{
                                                            color: 'white',
                                                            fontSize: '1rem',
                                                            fontWeight: 700,
                                                            '&:hover': {
                                                                fontSize: '1.03rem',
                                                            },
                                                        }}
                                                    >
                                                        {hasTeam ? 'Manage Team' : 'Create Team'}
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </ListItem>
                                    </List>
                                ) : (
                                    <List sx={{ width: '90%', paddingTop: '0px' }}>
                                        <ListItem
                                            disablePadding
                                            sx={{ color: 'white', ' & > a': { color: 'white' } }}
                                        >
                                            <ButtonLink
                                                to="/team/create"
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                fullWidth
                                            >
                                                Create Team
                                            </ButtonLink>
                                        </ListItem>
                                    </List>
                                )}
                            </>
                        ) : (
                            <List sx={{ width: '90%' }}>
                                <ListItem
                                    disablePadding
                                    sx={{ color: 'white', ' & > a': { color: 'white' } }}
                                >
                                    <ButtonLink to="/login" variant="contained" color="primary" size="large" fullWidth>
                                        Login
                                    </ButtonLink>
                                </ListItem>
                            </List>
                        )}

                        {!!profile && (
                            <div style={{ 
                                width: '100%', 
                                alignItems: 'center', 
                                display: 'flex', 
                                flex: 1, 
                                flexDirection: 'column', 
                                justifyContent: 'flex-end', 
                                padding: 0 
                            }}>
                                <List sx={{ width: '90%', paddingBottom: '0px', paddingTop: '0px' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            backgroundColor: '#181D31',
                                            width: 'auto',
                                            borderRadius: '10px',
                                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            paddingLeft: '1.25rem',
                                            paddingRight: '1.25rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: '#2c324a',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <UserGameButton />
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        )}
                    </Box>

                    <div style={{ marginTop: 'auto' }}>
                        <Divider variant="middle" sx={{ marginTop: '0.5rem' }} />

                        <div
                            style={{
                                paddingTop: '0.5rem',
                                paddingRight: '16px',
                                paddingLeft: '16px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '0.75rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <a
                                    style={{ color: 'white' }}
                                    href={'https://discord.gg/SMKDMB94rx'}
                                    target={'_blank'}
                                    title={'Twitter'}
                                >
                                    <BsDiscord size={25} />
                                </a>
                                <a
                                    style={{ color: 'white' }}
                                    href={'https://twitter.com/praccgg'}
                                    target={'_blank'}
                                    title={'Discord'}
                                >
                                    <FaXTwitter size={25} />
                                </a>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </Box>

            <Slide direction="right" container={containerRef.current} in={showNotification} mountOnEnter unmountOnExit>
                <div className={classes.notificationDrawer}>
                    <NotificationDrawer handleCloseDrawer={() => setShowNotification(false)} />
                </div>
            </Slide>

            <Slide direction="right" container={containerRef.current} in={msgDrawerOpen} mountOnEnter unmountOnExit>
                <div className={classes.chatDrawer2}>
                    <ChatDrawer />
                </div>
            </Slide>
        </Box>
    );
}

export default connect(
    (state: any) => ({
        profile: state.app.profile,
        isLoading: state.app.isLoading,
        isResendingConfirmationEmail: state.app.isResendingConfirmationEmail,
        isEmailUnconfirmedAlertHidden: state.app.isEmailUnconfirmedAlertHidden,
        isRatingOpenAlertHidden: state.app.isRatingOpenAlertHidden,
        msgDrawerOpen: state.app.msgDrawerOpen,
        notifications: state.app.notifications,
        websocket: state.app.websocket,
        isLoadingNotifications: state.app.isLoadingNotifications,
        activeChats: state.app.activeChats,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openChat,
                selectTeam,
                loadProfile,
                updateProfile,
                updateTeamProfile,
                resendConfirmationEmail,
                setEmailUnconfirmedHidden,
                openMsgs,
                showNotifySnack,
                addNotification,
                setUnreadMsgs,
                setRatingIsOpenHidden,
                updateIsLoadingNotifications,
            },
            dispatch
        )
)(MobileMenu);

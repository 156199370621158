import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ReactCountryFlag from 'react-country-flag';
import { usePing } from 'utils/usePing';
import { getNetworkIconColor } from 'utils/server-locations';

interface ServerLocationSelectProps extends SelectProps {
    previewPing?: boolean;
}

let counter = 0;

function ServerLocationSelect({ previewPing, fullWidth, sx, label, ...rest }: ServerLocationSelectProps) {
    const sortedCities = usePing();
    const id = React.useMemo(() => `server-location-select-${counter++}`, []);

    const renderSelectedValue = (value: string) => {
        const selectedCity = sortedCities.find((city) => city.city === value);
        if (!selectedCity) return null;
        return (
            <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                <ReactCountryFlag
                    countryCode={selectedCity.code}
                    style={{ width: 20, height: 20 }}
                    svg
                    title={`${selectedCity.region} - ${selectedCity.country}`}
                />
                <Typography sx={{ marginLeft: 1, flexGrow: 1 }}>
                    {selectedCity.region} - {selectedCity.country}
                </Typography>
                { previewPing && <Typography sx={{ marginBottom: '-0.2rem' }}>
                    <SignalCellularAltIcon sx={{ color: getNetworkIconColor(selectedCity.ping) }} />
                </Typography> }
                { previewPing && <Typography sx={{ fontWeight: 'bold' }}>
                    {selectedCity.ping !== null ? `${selectedCity.ping} ms` : '...'}
                </Typography> }
            </div>
        );
    };

    return (
        <FormControl fullWidth={fullWidth} sx={sx}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                label={label}
                {...rest}
                labelId={id}
                renderValue={(selected) => renderSelectedValue(selected as string)}
            >
                {sortedCities.map((city) => (
                    <MenuItem value={city.city} key={city.city}>
                        <div
                            style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                display: 'flex',
                            }}
                        >
                            <ReactCountryFlag
                                countryCode={city.code}
                                style={{ width: 20, height: 20 }}
                                svg
                                title={`${city.region} - ${city.country}`}
                            />
                            <Typography sx={{ marginLeft: 1, flexGrow: 1 }}>
                                {city.region} - {city.country}
                            </Typography>
                            <Typography sx={{ marginBottom: '-0.2rem' }}>
                                <SignalCellularAltIcon sx={{ color: getNetworkIconColor(city.ping) }} />
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {city.ping !== null ? `${city.ping} ms` : '...'}
                            </Typography>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ServerLocationSelect;

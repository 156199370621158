import React, { MutableRefObject } from 'react';
import axios from 'axios';
import { handleError } from '../../components/snackbar/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useProUser } from '../billing/utils';
import { Lobby } from '../../model/lobbies';
import WebsocketManager from '../../utils/websocket-manager';
import { Subscription } from 'rxjs';

export type GameModeMap = {
    Name: string;
    Image: string;
    VipOnly: boolean;
};

export function useGameModeMaps(mode: string): { mapsLoaded: boolean; maps: GameModeMap[] } {
    const dispatch = useDispatch();
    const [mapsLoaded, setLoaded] = React.useState(false);
    const [maps, setMaps] = React.useState([]);

    React.useEffect(() => {
        async function load() {
            try {
                const res = await axios.get(`/api/play/modes/${mode}/maps`);
                setMaps(res.data);
                setLoaded(true);
            } catch (e) {
                handleError(e)(dispatch);
            }
        }

        load();
    }, [mode]);

    return { mapsLoaded, maps };
}

let numSubscribers = 0;
let connectionSub: Subscription | null = null;

type LobbyUpdateCallback = (newLobby: Lobby) => void;

export function useLobbyUpdates(cb: LobbyUpdateCallback) {
    const websocketManager = useSelector((state: any) => state.app.websocket) as WebsocketManager;
    const activeCallback: MutableRefObject<LobbyUpdateCallback | null> = React.useRef<LobbyUpdateCallback>(null);

    activeCallback.current = cb;

    React.useEffect(() => {
        const eventSub = websocketManager.subscribe((msg) => {
            if (msg.MessageType === 'lobby.Updated') {
                const newLobby = msg.Payload.Lobby;
                activeCallback.current?.(newLobby);
            }
        });

        const setUpdates = (subscribe: boolean) => {
            websocketManager.send('lobby.SetUpdates', {
                Subscribe: subscribe,
            });
        };

        if (numSubscribers === 0) {
            setUpdates(true);

            connectionSub = websocketManager.subscribeConnectionStatus((isConnected) => {
                if (!isConnected) {
                    return;
                }

                setUpdates(true);
            });
        }
        numSubscribers++;

        return () => {
            numSubscribers--;
            if (numSubscribers === 0) {
                connectionSub?.unsubscribe();
                setUpdates(false);
            }

            eventSub.unsubscribe();
        };
    }, []);
}

import { useState, useEffect } from 'react';
import { getAllServerLocations } from './server-locations';


interface City {
    city: string;
    country: string;
    region: string;
    code: string;
    dsn: string;
    ping: number | null;
}

interface PingTimes {
    [key: string]: number | null;
}

export function usePing() {
    const [cities, setCities] = useState<City[]>([]);
    const [sortedCities, setSortedCities] = useState<City[]>([]);
    const [pingTimes, setPingTimes] = useState<PingTimes>({});

    useEffect(() => {
        // Fetch server locations and initialize cities with null ping values
        const initialCities = getAllServerLocations().map((city) => ({ ...city, ping: null }));
        setCities(initialCities);

        const doPing = (location: City, socket: WebSocket, tests: number[]) => {
            const startTime = Date.now();
            socket.onmessage = (message) => {
                const decoded = JSON.parse(message.data);
                if (decoded.cmd === 'datPong') {
                    tests.push(Date.now() - startTime);
                    if (tests.length < 7) {
                        doPing(location, socket, tests);
                    } else {
                        tests.sort((a, b) => a - b);
                        setPingTimes((prev) => ({
                            ...prev,
                            [location.city]: tests[0],
                        }));
                        socket.close();
                    }
                }
            };
            socket.send(JSON.stringify({ cmd: 'datPing' }));
        };

        // Create WebSocket connections and perform pings
        const sockets = initialCities.map((city) => {
            if (city.dsn) {
                const socket = new WebSocket(`wss://${city.dsn}/console-server/`);
                socket.onopen = () => {
                    doPing(city, socket, []);
                };
                return socket;
            }
            return null;
        });

        return () => {
            sockets.forEach((socket) => {
                if (socket) {
                    socket.close();
                }
            });
        };
    }, []);

    useEffect(() => {
        const updatedCities = cities
            .map((city) => ({
                ...city,
                ping: pingTimes[city.city] ?? city.ping,
            }))
            .sort((a, b) => {
                if (a.ping === null) return 1;
                if (b.ping === null) return -1;
                if (a.ping === -1) return 1;
                if (b.ping === -1) return -1;
                return a.ping - b.ping;
            });

        setSortedCities(updatedCities);
    }, [cities, pingTimes]);

    return sortedCities;
}

import React from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Avatar,
    ToggleButtonGroup,
    ToggleButton,
    Chip,
    Stack,
} from '@mui/material';
import { PraccDialog } from 'components/layout/dialogs';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { Profile } from '../../model/profile';
import { handleError } from '../../components/snackbar/reducer';
import axios, { AxiosError } from 'axios';
import { SiFaceit } from 'react-icons/si';
import { MdOutlineCategory, MdMicNone, MdOutlineLocalFireDepartment } from 'react-icons/md';
import { LuShieldCheck } from 'react-icons/lu';
import { GrLocation } from 'react-icons/gr';
import { HiOutlineMap } from 'react-icons/hi';
import HeaderBg from '../../assets/lobbies/header-image.png';
import MicDisabled from '@mui/icons-material/MicOff';
import Mic from '@mui/icons-material/Mic';
import User from '../../assets/lobbies/user.svg';
import SteamIcon from '../../assets/lobbies/steam-icon.svg';
import ShieldCheckEnabled from '@mui/icons-material/VerifiedUser';
import ShieldCheckDisabled from '@mui/icons-material/RemoveModerator';
import ServerLocationSelect from 'components/ServerLocationSelect';
import SimpleServerLocationSelect from 'components/SimpleServerLocationSelect';
import csgoMaps from '../../utils/maps';
import { useLobbyUpdates } from './utils';
import { Lobby } from '../../model/lobbies';
import { browserHistory } from '../../store';
import { getAllServerLocations } from '../../utils/server-locations';
import Group from '@mui/icons-material/Groups';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Fire from '@mui/icons-material/Whatshot';

const supportedMaps = new Set<string>([
    'de_dust2',
    'de_mirage',
    'de_anubis',
    'de_ancient',
    'de_inferno',
    'de_nuke',
    'de_vertigo',
    'de_train',
    'de_overpass',
    'de_cache',
]);

const lobbyTypes = [
    { key: 'public', name: 'Public', icon: Group },
    { key: 'private', name: 'Private', icon: User },
    { key: 'application', name: 'Application', icon: LockOutlined },
];

type LobbySettings = {
    voice: boolean;
    friendlyFire: boolean;
    antiCheat: boolean;
    location: string;
    map: string;
    category: string;
};

export const SettingsDialog = ({
    lobby,
    open,
    onConfirm,
    onClose,
}: {
    lobby?: Lobby;
    open: boolean;
    onConfirm: (newSettings: LobbySettings) => Promise<void>;
    onClose: () => void;
}) => {
    const [voice, setVoice] = React.useState(true);
    const [friendlyFire, setFriendlyFire] = React.useState(true);
    const [antiCheat, setAntiCheat] = React.useState(true);
    const [location, setLocation] = React.useState('dusseldorf');
    const [createModalSelectedMap, setCreateModalSelectedMap] = React.useState('random');
    const [category, setCategory] = React.useState('public');
    const [isWorking, setWorking] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (open) {
            if (lobby) {
                setVoice(lobby.Settings.Voice);
                setFriendlyFire(lobby.Settings.FriendlyFire);
                setAntiCheat(lobby.Settings.AntiCheat);
                setLocation(lobby.Settings.Location);
                setCategory(lobby.Type);
                setCreateModalSelectedMap(lobby.Settings.Map);
            } else {
                setVoice(true);
                setFriendlyFire(true);
                setAntiCheat(true);
                setLocation(localStorage.getItem('request_server.location') ?? 'dusseldorf');
                setCategory('public');
                setCreateModalSelectedMap('random');
            }
        }
    }, [open]);

    const handleCreate = async (e: any) => {
        e.preventDefault();

        setWorking(true);
        try {
            let selectedMap = createModalSelectedMap;
            if (createModalSelectedMap == 'random') {
                selectedMap = csgoMaps[Math.floor(Math.random() * 100) % csgoMaps.length].label;
            }

            await onConfirm({
                voice,
                friendlyFire,
                antiCheat,
                location,
                map: selectedMap,
                category,
            });

            onClose();
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);
    };

    const lobbyTitle = lobby ? 'UPDATE LOBBY SETTINGS' : 'CREATE A LOBBY';

    return (
        <PraccDialog open={open} onClose={onClose}>
            <DialogTitle>{lobbyTitle}</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        minWidth: 390,
                        maxWidth: 390,
                    }}
                >
                    <FormControl fullWidth>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                marginBottom: '4px',
                            }}
                        >
                            <MdOutlineCategory size={20} />
                            <span>Category</span>
                        </Box>
                        <Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            SelectDisplayProps={{ style: { height: '40px' } }}
                            sx={{
                                '& .MuiSelect-select': {
                                    height: '40px',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem value="private">Private</MenuItem>
                            <MenuItem value="application">Application</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                            marginTop: '24px',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    marginBottom: '4px',
                                }}
                            >
                                <MdMicNone size={20} />
                                <span>Voice</span>
                            </Box>
                            <ToggleButtonGroup
                                sx={{
                                    '& > .MuiToggleButton-root': {
                                        border: '1px solid rgba(255, 255, 255, 0.12)',
                                        color: 'white',
                                        height: 35,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minWidth: '60px',
                                        padding: '0 16px',
                                    },
                                    '& > .MuiToggleButton-root.Mui-selected, & > .MuiToggleButton-root.Mui-selected:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.16)',
                                        color: 'white',
                                    },
                                }}
                                value={voice.toString()}
                                onChange={() => setVoice((prev) => !prev)}
                            >
                                <ToggleButton value="true">
                                    <span>On</span>
                                </ToggleButton>
                                <ToggleButton value="false">
                                    <span style={{ color: "#A6ACC0" }}>Off</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    marginBottom: '4px',
                                }}
                            >
                                <MdOutlineLocalFireDepartment size={20} />
                                <span>Friendly Fire</span>
                            </Box>
                            <ToggleButtonGroup
                                sx={{
                                    '& > .MuiToggleButton-root': {
                                        border: '1px solid rgba(255, 255, 255, 0.12)',
                                        color: 'white',
                                        height: 35,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minWidth: '60px',
                                        padding: '0 16px',
                                    },
                                    '& > .MuiToggleButton-root.Mui-selected, & > .MuiToggleButton-root.Mui-selected:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.16)',
                                        color: 'white',
                                    },
                                }}
                                value={friendlyFire.toString()}
                                onChange={() => setFriendlyFire((prev) => !prev)}
                            >
                                <ToggleButton value="true">
                                    <span>On</span>
                                </ToggleButton>
                                <ToggleButton value="false">
                                    <span style={{ color: "#A6ACC0" }}>Off</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    marginBottom: '4px',
                                }}
                            >
                                <LuShieldCheck size={20} />
                                <span>Anti-Cheat</span>
                            </Box>
                            <ToggleButtonGroup
                                sx={{
                                    '& > .MuiToggleButton-root': {
                                        border: '1px solid rgba(255, 255, 255, 0.12)',
                                        color: 'white',
                                        height: 35,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minWidth: '60px',
                                        padding: '0 16px',
                                    },
                                    '& > .MuiToggleButton-root.Mui-selected, & > .MuiToggleButton-root.Mui-selected:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.16)',
                                        color: 'white',
                                    },
                                }}
                                value={antiCheat.toString()}
                                onChange={() => setAntiCheat((prev) => !prev)}
                            >
                                <ToggleButton value="true">
                                    <span>On</span>
                                </ToggleButton>
                                <ToggleButton value="false">
                                    <span style={{ color: "#A6ACC0" }}>Off</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </FormControl>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            marginTop: '24px',
                            marginBottom: '4px',
                        }}
                    >
                        <GrLocation size={20} />
                        <span>Location</span>
                    </Box>
                    <FormControl fullWidth>
                        <ServerLocationSelect
                            value={location}
                            onChange={(e) => {
                                const newLocation = e.target.value as string;
                                setLocation(newLocation);
                                localStorage.setItem('request_server.location', newLocation);
                            }}
                            sx={{
                                '& .MuiSelect-select': {
                                    height: '40px',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                            fullWidth
                            previewPing
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                marginTop: '24px',
                                marginBottom: '4px',
                            }}
                        >
                            <HiOutlineMap size={20} />
                            <span>Map</span>
                        </Box>
                        <Select
                            labelId={'map-select-label'}
                            id={'map-select'}
                            SelectDisplayProps={{ style: { height: '40px' } }}
                            defaultValue={createModalSelectedMap}
                            onChange={(e) => setCreateModalSelectedMap(e.target.value)}
                            sx={{
                                '& .MuiSelect-select': {
                                    height: '40px',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            <MenuItem value="random">Random</MenuItem>

                            {csgoMaps.map((map) => {
                                if (!supportedMaps.has(map.label)) {
                                    return null;
                                }

                                return (
                                    <MenuItem value={map.label}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img src={map.pin} width={25} height={25} alt="" />
                                            <Typography sx={{ fontWeight: 'bold', marginLeft: 1 }}>
                                                {map.label}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        margin: '0 16px 20px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    <Button
                        onClick={handleCreate}
                        variant="contained"
                        size="large"
                        disabled={isWorking}
                        sx={{
                            color: '#333749',
                            backgroundColor: '#5595F9',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                    >
                        {lobbyTitle}
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                    >
                        CANCEL
                    </Button>
                </Box>
            </DialogActions>
        </PraccDialog>
    );
};

function LobbyRow({ lobby }: { lobby: Lobby }) {
    const map = csgoMaps.find((m) => m.label === lobby.Settings.Map);
    const category = lobbyTypes.find((t) => t.key === lobby.Type);
    const location = getAllServerLocations().find((l) => l.city === lobby.Settings.Location);
    const [isWorking, setWorking] = React.useState(false);
    const dispatch = useDispatch();

    const handleJoin = React.useCallback(async () => {
        setWorking(true);
        try {
            await axios.post(`/api/play/lobbies/${lobby.Uid}/join`);
            browserHistory.push(`/play/lobbies/${lobby.Uid}`);
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);
    }, [lobby]);

    const [averageFaceItElo, averagePlayMinutes] = React.useMemo(() => {
        let totalFaceIt = 0;
        let totalSteam = 0;
        let totalCount = 0;

        for (const slot of lobby.Slots) {
            if (!slot.User) {
                continue;
            }

            totalFaceIt += slot.User.FaceItElo || 0;
            totalSteam += slot.User.CsPlayMinutes || 0;
            totalCount++;
        }

        if (totalCount === 0) {
            return [0, 0];
        }

        return [totalFaceIt / totalCount, totalSteam / totalCount];
    }, [lobby]);

    return (
        <Box
            sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.23)',
                padding: '26px 0px 18px',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        borderRightWidth: 1,
                        borderRightColor: '#21263B',
                        borderRightStyle: 'solid',
                        paddingRight: '10px',
                        gap: '25px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography sx={{ fontSize: '18px' }}>{lobby.Title}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Chip
                                sx={{ borderRadius: '5px', backgroundColor: '#212538' }}
                                icon={<SiFaceit color="#e4621b" size={20} />}
                                label={`${averageFaceItElo} ELO`}
                                onClick={() => {}}
                            />
                            <Chip
                                sx={{ borderRadius: '5px', backgroundColor: '#212538' }}
                                icon={<img src={SteamIcon} style={{ width: 20, height: 20 }} />}
                                label={`${averagePlayMinutes / 60}h`}
                                onClick={() => {}}
                            />
                            <Chip
                                sx={{ borderRadius: '5px', backgroundColor: '#212538' }}
                                icon={<img src={map?.pin} style={{ height: 20 }} />}
                                label={lobby.Settings.Map}
                                onClick={() => {}}
                            />
                            {location && (
                                <Chip
                                    sx={{ borderRadius: '5px', backgroundColor: '#212538' }}
                                    icon={
                                        <ReactCountryFlag
                                            style={{ width: 20, height: 20 }}
                                            countryCode={location.code}
                                            svg
                                        />
                                    }
                                    label={location.region}
                                    onClick={() => {}}
                                />
                            )}
                            {category && (
                                <Chip
                                    sx={{ borderRadius: '5px', backgroundColor: '#212538' }}
                                    icon={
                                        category && (
                                            <category.icon
                                                sx={{
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        )
                                    }
                                    label={category.name}
                                    onClick={() => {}}
                                />
                            )}
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        {lobby.Slots.map((slot, index) => {
                            if (!slot.User) {
                                return (
                                    <Button
                                        key={index}
                                        sx={{
                                            width: 'calc((100% - 0px) / 10)',
                                            height: '110px',
                                            borderRadius: '10px',
                                            padding: '4px',
                                            background: '#0C1227',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: '300',
                                                fontSize: '14px',
                                                lineHeight: '16.41px',
                                                color: '#A6ACC0',
                                            }}
                                        >
                                            FREE
                                            <br />
                                            SLOT
                                        </Typography>
                                    </Button>
                                );
                            }

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        width: 'calc((100% - 0px) / 10)',
                                        height: '110px',
                                        borderRadius: '10px',
                                        padding: '5px',
                                        background: 'linear-gradient(to bottom, #0F1835, #182757, #0F1835)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: '100%',
                                            height: 65,
                                            borderRadius: '6px',
                                        }}
                                        variant="rounded"
                                        src={slot.User.Avatar}
                                    />
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                            <SiFaceit color="#e4621b" size={15} />
                                            <Typography
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '10px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {slot.User.FaceItElo} ELO
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                            <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                            <Typography
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '10px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {Math.floor(slot.User.CsPlayMinutes / 60)}h
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '222px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            width: 185,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <Typography variant="h3" sx={{ fontSize: '24px' }}>
                            {lobby.Slots.reduce((current, slot) => current + (slot.User ? 1 : 0), 0)} /{' '}
                            {lobby.Slots.length}
                        </Typography>
                        {lobby.Type === 'application' ? (
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    borderWidth: 1,
                                    borderColor: '#2976EE',
                                    borderStyle: 'solid',
                                    borderRadius: '4px',
                                    minWidth: '40px',
                                }}
                                disabled={isWorking}
                                onClick={handleJoin}
                            >
                                <Typography variant="h3" sx={{ fontSize: '16px' }}>
                                    Apply
                                </Typography>
                            </Button>
                        ) : lobby.Type !== 'public' ? (
                            <></>
                        ) : (
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    borderWidth: 1,
                                    borderColor: '#2976EE',
                                    borderStyle: 'solid',
                                    borderRadius: '4px',
                                    minWidth: '40px',
                                }}
                                disabled={isWorking}
                                onClick={handleJoin}
                            >
                                <Typography variant="h3" sx={{ fontSize: '16px' }}>
                                    JOIN
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function Lobbies() {
    const dispatch = useDispatch();
    const [openCreateModal, setOpenCreateModal] = React.useState(false);
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const [location, setLocation] = React.useState(localStorage.getItem('request_server.location') ?? 'All');
    const [lobbies, setLobbies] = React.useState<Lobby[]>([]);
    const [voiceSelect, setVoiceSelect] = React.useState<string>('All');
    const [antiCheat, setAntiCheat] = React.useState<string>('All');
    const [friendlyFire, setFriendlyFire] = React.useState<string>('All');
    const [category, setCategory] = React.useState<string>('All');
    const [friendlyFireFilter, setFriendlyFireFilter] = React.useState<string>('All');

    async function load() {
        if (profile) {
            try {
                const res = await axios.get('/api/play/lobbies/active');
                browserHistory.push(`/play/lobbies/${res.data.Uid}`);
                return;
            } catch (e: any) {
                let isHandled = false;
                if (e.isAxiosError) {
                    const axiosErr = e as AxiosError;
                    if (axiosErr.response?.status === 404) {
                        isHandled = true;
                    }
                }

                if (!isHandled) {
                    handleError(e)(dispatch);
                    return;
                }
            }
        }

        try {
            const res = await axios.get('/api/play/lobbies');
            setLobbies(res.data);
        } catch (e: any) {
            handleError(e)(dispatch);
        }
    }

    useLobbyUpdates((lobby) => {
        if (profile && lobby.Slots.findIndex((slot) => slot.User?.ID === profile.ID) >= 0) {
            browserHistory.push(`/play/lobbies/${lobby.Uid}`);
            return;
        }

        setLobbies((currentLobbies) => {
            const newLobbies = [...currentLobbies];
            const index = currentLobbies.findIndex((l) => l.Uid === lobby.Uid);
            if (index >= 0) {
                newLobbies[index] = lobby;
            } else {
                newLobbies.push(lobby);
            }

            return newLobbies;
        });
    });

    React.useEffect(() => {
        load();
    }, [profile?.ID]);

    return (
        <Box
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#181D31',
            }}
        >
            <Box
                sx={{
                    height: '225px',
                    width: '100%',
                    display: 'flex',
                    marginBottom: '12px',
                    gap: '10px',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                        backgroundImage: `url(${HeaderBg})`,
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        position: 'relative',
                        paddingLeft: '30px',
                        justifyContent: 'center',
                    }}
                >
                    {/* <Box
                        sx={{
                            position: 'absolute',
                            width: '225px',
                            height: '225px',
                            right: 23,
                            backgroundImage: `url(${CSPlayer})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    />
                    <Typography
                        variant='h5'
                        sx={{
                            fontSize: '29px',
                            lineHeight: '32px',
                            fontWeight: '700',
                            color: 'white'
                        }}
                    >
                        Create or join custom lobbies to play with friends,
                        <Typography sx={{
                            fontSize: '29px',
                            lineHeight: '32px',
                            fontWeight: '400',
                            color: '#E0E0E0',
                        }} >
                            teammates, viewers, or other players who suit you<br />and make the game fun.
                        </Typography>
                    </Typography> */}
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                }}
            >
                {/* <Card
                    raised
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                gap: '10px'
                            }}
                        >
                            <PraccPaperHeader sx={{ marginBottom: 0, fontSize: '20px', lineHeight: '32px' }}>OPEN FOR INVITES</PraccPaperHeader>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginRight: '30px',
                                        alignItems: 'center',
                                        gap: '16px'
                                    }}
                                >
                                    <Avatar sx={{ width: 36, height: 36 }} src={'https://picsum.photos/200/300'} />
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: '16px'
                                        }}
                                    >crisbyyy</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: '288px',
                                            height: '40px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderWidth: 1,
                                            borderColor: '#39405B',
                                            borderStyle: 'solid',
                                            backgroundColor: '#13182D',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontSize: '18px',
                                                lineHeight: '20px'
                                            }}
                                            color='#2976EE'
                                        >
                                            10:00
                                        </Typography>
                                    </Box>
                                    <Button
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            borderWidth: 1,
                                            borderColor: '#39405B',
                                            borderStyle: 'solid',
                                            backgroundColor: '#13182D',
                                            borderRadius: '4px',
                                            minWidth: '40px',
                                        }}
                                    >
                                        <img src={PlayIcon} style={{ width: '24px', height: '24px' }} />
                                    </Button>
                                    <Button
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            borderWidth: 1,
                                            borderColor: '#39405B',
                                            borderStyle: 'solid',
                                            backgroundColor: '#13182D',
                                            borderRadius: '4px',
                                            minWidth: '40px',
                                        }}
                                    >
                                        <img src={RefreshIcon} style={{ width: '29px', height: '29px' }} />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card> */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            gap: '10px',
                        }}
                    >
                        {/*<PraccPaperHeader sx={{ marginBottom: 0, fontSize: '20px', lineHeight: '32px' }}>LOBBIES</PraccPaperHeader>*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    paddingRight: '10px',
                                }}
                            >
                                {/*<Box*/}
                                {/*    sx={{*/}
                                {/*        flex: 1,*/}
                                {/*        height: '40px'*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <TextField*/}
                                {/*        fullWidth*/}
                                {/*        placeholder="Search lobby"*/}
                                {/*        autoComplete="off"*/}
                                {/*        inputProps={{*/}
                                {/*            style: {*/}
                                {/*                fontSize: 16,*/}
                                {/*                fontWeight: '300',*/}
                                {/*                height: 40,*/}
                                {/*                padding: '0 14px',*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                                <FormControl
                                    sx={{
                                        minWidth: '160px',
                                    }}
                                >
                                    <InputLabel id={'category-select-label'}>Category</InputLabel>
                                    <Select
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                        SelectDisplayProps={{ style: { height: '40px' } }}
                                        renderValue={(selected) => {
                                            if (selected === 'All') return 'All';
                                            const selectedType = lobbyTypes.find(t => t.key === selected.toLowerCase());
                                            if (!selectedType) return selected;
                                            
                                            return (
                                                <Box sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                }}>
                                                    {selectedType.key === 'private' ? (
                                                        <img src={User} style={{ width: 20, height: 20 }} />
                                                    ) : (
                                                        <selectedType.icon sx={{ width: 20, height: 20 }} />
                                                    )}
                                                    <span>{selectedType.name}</span>
                                                </Box>
                                            );
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                height: '40px',
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: category === 'All' ? '14px' : '9px',
                                            },
                                        }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="public" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px',
                                            minWidth: '120px'
                                        }}>
                                            <Group sx={{ width: 20, height: 20 }} />
                                            <span>Public</span>
                                        </MenuItem>
                                        <MenuItem value="private" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px',
                                            minWidth: '120px'
                                        }}>
                                            <img src={User} style={{ width: 20, height: 20 }} />
                                            <span>Private</span>
                                        </MenuItem>
                                        <MenuItem value="application" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '12px',
                                            minWidth: '120px'
                                        }}>
                                            <LockOutlined sx={{ width: 20, height: 20 }} />
                                            <span>Application</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {/*<FormControl>*/}
                                {/*    <CountrySelect*/}
                                {/*        value={organization?.Country ?? ''}*/}
                                {/*        onChange={(_: any, Country: any) => onChangeCountry({*/}
                                {/*            ...organization,*/}
                                {/*            Country*/}
                                {/*        } as Organization)}*/}
                                {/*    />*/}
                                {/*</FormControl>*/}
                                <FormControl
                                    sx={{
                                        minWidth: '190px',
                                    }}
                                >
                                    <SimpleServerLocationSelect
                                        label="Location"
                                        value={location}
                                        onChange={(e) => {
                                            const newLocation = e.target.value as string;
                                            setLocation(newLocation);
                                            localStorage.setItem('request_server.location', newLocation);
                                        }}
                                        sx={{
                                            minWidth: '130px',
                                            '& .MuiSelect-select': {
                                                height: '40px',
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        minWidth: '106px',
                                    }}
                                >
                                    <InputLabel id={'voice-select-label'}>Voice</InputLabel>
                                    <Select
                                        value={voiceSelect}
                                        onChange={(e) => setVoiceSelect(e.target.value)}
                                        SelectDisplayProps={{ style: { height: '40px' } }}
                                        renderValue={(selected) => {
                                            if (selected === 'All') return 'All';
                                            return (
                                                <Box sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}>
                                                    {selected === 'on' ? (
                                                        <Mic sx={{ width: 20, height: 20 }} />
                                                    ) : (
                                                        <MicDisabled sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                                    )}
                                                    <span style={{ color: selected === 'off' ? "#A6ACC0" : "inherit" }}>
                                                        {selected === 'on' ? 'ON' : 'OFF'}
                                                    </span>
                                                </Box>
                                            );
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                height: '40px',
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: voiceSelect === 'All' ? '14px' : '9px',
                                            },
                                        }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="on" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            minWidth: '80px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                minWidth: '80px'
                                            }}>
                                                <Mic sx={{ width: 20, height: 20 }} />
                                                <span>On</span>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="off" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            minWidth: '80px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                minWidth: '80px'
                                            }}>
                                                <MicDisabled sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                                <span style={{ color: "#A6ACC0" }}>Off</span>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        minWidth: '106px',
                                    }}
                                >
                                    <InputLabel id={'cheat-select-label'}>Anti-Cheat</InputLabel>
                                    <Select
                                        value={antiCheat}
                                        onChange={(e) => setAntiCheat(e.target.value)}
                                        SelectDisplayProps={{ style: { height: '40px' } }}
                                        renderValue={(selected) => {
                                            if (selected === 'All') return 'All';
                                            return (
                                                <Box sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}>
                                                    {selected === 'ON' ? (
                                                        <ShieldCheckEnabled sx={{ width: 20, height: 20 }} />
                                                    ) : (
                                                        <ShieldCheckDisabled sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                                    )}
                                                    <span style={{ color: selected === 'OFF' ? "#A6ACC0" : "inherit" }}>
                                                        {selected}
                                                    </span>
                                                </Box>
                                            );
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                height: '40px',
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: antiCheat === 'All' ? '14px' : '9px',
                                            },
                                        }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="ON" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            minWidth: '80px'
                                        }}>
                                            <ShieldCheckEnabled sx={{ width: 20, height: 20 }} />
                                            <span>ON</span>
                                        </MenuItem>
                                        <MenuItem value="OFF" sx={{ 
                                            paddingLeft: '9px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            minWidth: '80px'
                                        }}>
                                            <ShieldCheckDisabled sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                            <span style={{ color: "#A6ACC0" }}>OFF</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        minWidth: 120,
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                        },
                                    }}
                                >
                                    <InputLabel id="friendly-fire-filter-label">Friendly Fire</InputLabel>
                                    <Select
                                        labelId="friendly-fire-filter-label"
                                        value={friendlyFireFilter}
                                        onChange={(e) => setFriendlyFireFilter(e.target.value)}
                                        label="Friendly Fire"
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (selected === 'All') return 'All';
                                            return (
                                                <Box sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}>
                                                    <Box sx={{ position: 'relative', width: 20, height: 20 }}>
                                                        <Fire sx={{ 
                                                            width: 20, 
                                                            height: 20, 
                                                            color: selected === 'off' ? "#A6ACC0" : "inherit" 
                                                        }} />
                                                        {selected === 'off' && (
                                                            <Box sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                                <Box sx={{
                                                                    width: '100%',
                                                                    height: '2px',
                                                                    backgroundColor: '#A6ACC0',
                                                                    transform: 'rotate(45deg)',
                                                                }} />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <span style={{ color: selected === 'off' ? "#A6ACC0" : "inherit" }}>
                                                        {selected === 'on' ? 'ON' : 'OFF'}
                                                    </span>
                                                </Box>
                                            );
                                        }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="on">
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                minWidth: '80px'
                                            }}>
                                                <Fire sx={{ width: 20, height: 20 }} />
                                                <span>On</span>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="off">
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                minWidth: '80px'
                                            }}>
                                                <Box sx={{ position: 'relative', width: 20, height: 20 }}>
                                                    <Fire sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <Box sx={{
                                                            width: '100%',
                                                            height: '2px',
                                                            backgroundColor: '#A6ACC0',
                                                            transform: 'rotate(45deg)',
                                                        }} />
                                                    </Box>
                                                </Box>
                                                <span style={{ color: "#A6ACC0" }}>Off</span>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: 222,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: 40, width: 185, color: 'white', backgroundColor: '#2976EE' }}
                                    onClick={(e) => {
                                        setOpenCreateModal(true);
                                        e.preventDefault();
                                    }}
                                >
                                    Create A Lobby
                                </Button>
                                <SettingsDialog
                                    open={openCreateModal}
                                    onClose={() => setOpenCreateModal(false)}
                                    onConfirm={async (newSettings) => {
                                        await axios.post('/api/play/lobbies', {
                                            Type: newSettings.category,
                                            Settings: {
                                                Voice: newSettings.voice,
                                                FriendlyFire: newSettings.friendlyFire,
                                                AntiCheat: newSettings.antiCheat,
                                                Location: newSettings.location,
                                                Map: newSettings.map,
                                            },
                                            Teams: [5, 5],
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    {lobbies.map((lobby, key) => (
                        <LobbyRow lobby={lobby} key={lobby.Uid} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default Lobbies;

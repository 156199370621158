import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { Link as RoutingLink } from 'react-router-dom';

interface RouterLinkProps extends LinkProps<RoutingLink> {}
function RouterLink({ children, ...rest }: RouterLinkProps) {
    return (
        <Link {...rest} component={RoutingLink}>
            {children}
        </Link>
    );
}

export default RouterLink;

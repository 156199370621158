import React from 'react';
import { Tabs, Tab, Box, useMediaQuery, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { GAME_ID_CSGO } from '../../utils/games';

interface TabInfo {
    label: string;
    icon?: React.ReactElement;
    path?: string;
    cs2Only?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

interface Props {
    tabs: TabInfo[];
    value: number;
    handleChange?: (_event: any, newValue: React.SetStateAction<number>) => void;
}

const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        width: '100%',
        backgroundColor: 'rgb(12, 17, 38)',
        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        background: 'inherit',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5rem',
        '& .MuiTabs-flexContainer': {
            display: 'flex',
            height: '45px',
            alignItems: 'center',
        },

        [theme.breakpoints.between('sm', 'md')]: {
            '& .MuiTabs-scroller': {
                overflowX: 'scroll !important',
            },
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '0px',
            width: 'auto',
        },
        '@media (max-width: 1024px)': {
            position: 'sticky',
            top: 0,
            zIndex: 10000,
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderTop: '1px solid rgba(89, 166, 255, 0.16)',
        },
    },
    activeTab: {
        backgroundColor: '#181D31',
        color: 'white',
        height: '40px',
        fontWeight: 'bold',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        minHeight: '45px',
        minWidth: 'auto',
        borderBottom: '3px solid #F50057',
        '@media (max-width: 1024px)': {
            flex: 1,
        },
    },
    tab: {
        color: 'white',
        fontWeight: 'bold',
        opacity: '1',
        minWidth: '50px',

        '@media (max-width: 1024px)': {
            flex: 1,
        },
    },
    tabWrapper: {
        width: '100%',
        display: 'flex',
        '@media (max-width: 1024px)': {
            flexDirection: 'column-reverse',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 10000,
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
    contentInnerWrapper: {
        width: '100%',
        marginBottom: '0.75rem',
        backgroundColor: 'rgb(12, 17, 38)',
        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
        '@media (max-width: 1024px)': {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            marginBottom: '0px',
        },
    },
}));

const CustomTabs: React.FC<Props> = ({ tabs, value, handleChange = () => {} }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const history = useHistory();
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;

    return (
        <Box className={classes.tabWrapper}>
            <Box className={classes.contentInnerWrapper}>
                <Tabs
                    value={value}
                    onChange={(_event, newValue) => handleChange(_event, newValue)}
                    variant="standard"
                    indicatorColor="secondary"
                    textColor="inherit"
                    className={classes.tabsContainer}
                >
                    {tabs.map((tab, index) => {
                        if (tab.cs2Only && profile) {
                            const isCs2 =
                                profile.Teams.some((t) => t.GameID === GAME_ID_CSGO) ||
                                profile.Subscriptions.some((s) => 'deathmatch' in s.ResolvedItems) ||
                                profile.Deathmatch.LitePlaytimePercentage > 0 ||
                                profile.Deathmatch.LiteUntil !== null;
                            if (!isCs2) {
                                return null;
                            }
                        }

                        return (
                            <Tab
                                key={index}
                                label={!isMobile ? tab.label : null}
                                icon={tab.icon}
                                iconPosition={isMobile ? 'top' : 'start'}
                                className={value === index ? classes.activeTab : classes.tab}
                                onClick={(e) => {
                                    if (tab.onClick) {
                                        tab.onClick(e);
                                    } else if (tab.path) {
                                        history.push(`${tab.path}`);
                                    }
                                }}
                            />
                        );
                    })}
                </Tabs>
            </Box>
        </Box>
    );
};

export default CustomTabs;

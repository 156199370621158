import { styled } from '@mui/material/styles';
import { Dialog, emphasize } from '@mui/material';

export const PraccDialog = styled(Dialog)(({ theme }) => ({
    '& > .MuiPaper-root': {
        position: 'relative',
        overflow: 'hidden',
        '::after': {
            position: 'absolute',
            zIndex: 0,
            content: '""',
            top: '-30%',
            left: 0,
            width: '100%',
            height: '150%',
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.05), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
        '> *': {
            zIndex: 1,
        },
    },
    '& .MuiDialogTitle-root': {
        background: `linear-gradient(45deg, ${emphasize(theme.palette.background.paper, 0.08)} 0%, ${emphasize(
            theme.palette.background.paper,
            0.1
        )} 10%, ${emphasize(theme.palette.background.paper, 0.12)} 15%, ${emphasize(
            theme.palette.background.paper,
            0.08
        )} 75%, ${emphasize(theme.palette.background.paper, 0.07)} 100%)`,
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${emphasize(theme.palette.background.paper, 0.03)}`,
        marginBottom: theme.spacing(2),
        boxShadow: `0px 1px 10px ${emphasize(theme.palette.background.paper, 0.03)}`,
    },
}));

// Example warm gradient:
// - Gold (#FFD75D) → Orange (#FFA752) → Red (#FF5C5C)
// Teal accent for the radial background and borderLeft (#26C6DA)

export const PraccAdDialog = styled(Dialog)(({ theme }) => ({
    '& > .MuiPaper-root': {
        position: 'relative',
        overflow: 'hidden',

        // Optional radial overlay on the entire paper background
        '::after': {
            position: 'absolute',
            zIndex: 0,
            content: '""',
            top: '-30%',
            left: 0,
            width: '100%',
            height: '150%',
            // Use a subtle radial so it doesn't overpower content
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(198, 40, 40, 0.05), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
        '> *': {
            zIndex: 1,
        },
    },

    // Keep the existing header gradient and accent
    '& .MuiDialogTitle-root': {
        background: 'linear-gradient(45deg, #FFD75D 0%, #FFA752 50%, #FF5C5C 100%)',
        borderLeft: '5px solid #C62828',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        marginBottom: theme.spacing(2),
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
    },

    // Adjust the content area below the header
    '& .MuiDialogContent-root': {
        // A light warm background that complements the gradient above
        backgroundColor: '#FFF5EE', // e.g. "Seashell" tone
        color: '#0c1126', // Dark text for contrast
        padding: theme.spacing(3), // Add some spacing
        // If you want to override the radial background in content only, add a local backgroundImage here
    },

    // Optional: style the actions area similarly
    '& .MuiDialogActions-root': {
        backgroundColor: '#FFF5EE',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(2),
    },
}));

const cities = [
    { city: 'beauharnois', country: 'Canada', region: 'Toronto', code: 'CA', dsn: 'beauharnois.dathost.net' },
    { city: 'new_york_city', country: 'USA', region: 'New York', code: 'US', dsn: 'new-york-city.dathost.net' },
    { city: 'los_angeles', country: 'USA · CA', region: 'Los Angeles', code: 'US', dsn: 'los-angeles.dathost.net' },
    { city: 'miami', country: 'USA · FL', region: 'Miami', code: 'US', dsn: 'miami.dathost.net' },
    { city: 'chicago', country: 'USA · IL', region: 'Chicago', code: 'US', dsn: 'chicago.dathost.net' },
    { city: 'portland', country: 'USA · WA', region: 'Seattle', code: 'US', dsn: 'portland.dathost.net' },
    { city: 'dallas', country: 'USA · TX', region: 'Dallas', code: 'US', dsn: 'dallas.dathost.net' },
    { city: 'atlanta', country: 'USA · GA', region: 'Atlanta', code: 'US', dsn: 'atlanta.dathost.net' },
    { city: 'denver', country: 'USA · CO', region: 'Denver', code: 'US', dsn: 'denver.dathost.net' },
    { city: 'copenhagen', country: 'Denmark', region: 'Copenhagen', code: 'DK', dsn: 'copenhagen.dathost.net' },
    { city: 'helsinki', country: 'Finland', region: 'Helsinki', code: 'FI', dsn: 'helsinki.dathost.net' },
    { city: 'strasbourg', country: 'France', region: 'Paris', code: 'FR', dsn: '	strasbourg.dathost.net' },
    { city: 'dusseldorf', country: 'Germany', region: 'Frankfurt', code: 'DE', dsn: 'dusseldorf.dathost.net' },
    { city: 'amsterdam', country: 'Netherlands', region: 'Amsterdam', code: 'NL', dsn: 'amsterdam.dathost.net' },
    { city: 'warsaw', country: 'Poland', region: 'Warsaw', code: 'PL', dsn: 'warsaw.dathost.net' },
    { city: 'barcelona', country: 'Spain', region: 'Madrid', code: 'ES', dsn: 'barcelona.dathost.net' },
    { city: 'stockholm', country: 'Sweden', region: 'Stockholm', code: 'SE', dsn: 'stockholm.dathost.net' },
    { city: 'istanbul', country: 'Turkey', region: 'Istanbul', code: 'TR', dsn: 'istanbul.dathost.net' },
    { city: 'bristol', country: 'United Kingdom', region: 'London', code: 'GB', dsn: 'bristol.dathost.net' },
    { city: 'sydney', country: 'Australia', region: 'Sydney', code: 'AU', dsn: 'sydney.dathost.net' },
    { city: 'sao_paulo', country: 'Brazil', region: 'São Paulo', code: 'BR', dsn: 'sao-paulo.dathost.net' },
    { city: 'santiago', country: 'Chile', region: 'Santiago', code: 'CL', dsn: 'santiago.dathost.net' },
    { city: 'hong_kong', country: 'Hong Kong', region: 'Hong Kong', code: 'HK', dsn: 'hong-kong.dathost.net' },
    { city: 'mumbai', country: 'India', region: 'Mumbai', code: 'IN', dsn: 'mumbai.dathost.net' },
    { city: 'tokyo', country: 'Japan', region: 'Tokyo', code: 'JP', dsn: 'tokyo.dathost.net' },
    { city: 'singapore', country: 'Singapore', region: 'Singapore', code: 'SG', dsn: 'singapore.dathost.net' },
    {
        city: 'johannesburg',
        country: 'South Africa',
        region: 'Johannesburg',
        code: 'ZA',
        dsn: 'johannesburg.dathost.net',
    },
    { city: 'seoul', country: 'South Korea', region: 'Seoul', code: 'KR', dsn: 'seoul.dathost.net' },
];

const simpleCities = [
    { city: 'All', country: 'All', region: 'All', code: null, dsn: '' },
    ...cities,
]

export function getAllServerLocations() {
    return cities;
}

export function getAllSimpleServerLocations() {
    return simpleCities;
}

export const getNetworkIconColor = (ping) => {
    if (ping === null || ping === -1) return 'grey';
    if (ping <= 70) return 'green';
    if (ping <= 150) return 'orange';
    return 'red';
};

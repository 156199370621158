import React from 'react';
import { Box, Button } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { browserHistory } from '../../store';
import BoostGate from '../boost/BoostGate';

export const DASH_MESSAGE = 'snack/DASH_MESSAGE';
export const HIDE_MESSAGE = 'snack/HIDE_MESSAGE';
export const QUEUE_MESSAGE = 'snack/QUEUE_MESSAGE';
export const FLUSH_QUEUE = 'snack/FLUSH_QUEUE';
export const DELETE_MESSAGE = 'snack/DELETE_MESSAGE';

const initialState = {
    show: false,
    msg: '',
    variant: '',
    queue: [],
};
let queueCounter = 0;

export default (state = initialState, action) => {
    switch (action.type) {
        case DASH_MESSAGE:
            return {
                ...state,
                show: true,
                msg: action.msg,
                variant: action.variant,
            };
        case QUEUE_MESSAGE:
            return {
                ...state,
                queue: [
                    ...state.queue,
                    {
                        msg: action.msg,
                        variant: action.variant,
                        code: action.code,
                        actions: action.actions,
                        keepOpen: action.keepOpen,
                        msgId: queueCounter++,
                        show: true,
                    },
                ],
            };
        case HIDE_MESSAGE:
            return {
                ...state,
                show: false,
            };
        case FLUSH_QUEUE:
            return {
                ...state,
                queue: [],
            };
        case DELETE_MESSAGE:
            return {
                ...state,
                queue: state.queue.map((m, i) => {
                    if (i !== action.id) {
                        return m;
                    }
                    return {
                        ...m,
                        show: false,
                    };
                }),
            };
        default:
            return state;
    }
};

export const queueMessage = (variant, message, actions = [], keepOpen = false) => {
    return (dispatch) => {
        dispatch({
            type: QUEUE_MESSAGE,
            msg: message,
            variant: variant,
            actions: actions,
            keepOpen,
        });
    };
};

export function logError(e) {
    if (e.request && e.config && e.response) {
        const event = {
            message: e.config.url + ' failed: status ' + e.response.status,
            transaction: e.config.url,
            extra: {
                responseData: e.response.data,
                responseHeaders: e.response.headers,
            },
        };

        if (process.env.NODE_ENV === 'production') {
            return Sentry.captureEvent(event);
        }
    }

    if (process.env.NODE_ENV === 'production') {
        return Sentry.captureException(e);
    } else {
        console.error(e);

        return 'none';
    }
}

function getReportActions(eventId) {
    const actions = [];
    if (process.env.NODE_ENV === 'production') {
        actions.push(
            <Button
                key="show-report-dialog"
                style={{ color: 'white', borderColor: 'white' }}
                variant="outlined"
                onClick={() => Sentry.showReportDialog({ eventId })}
            >
                Report Error
            </Button>
        );
    }

    return actions;
}

export function getErrorMessage(error) {
    if (error.response) {
        if ((error.response.status === 400 || error.response.status === 402) && error.response.data.Message) {
            if (error.response.data.Code === 'team_required') {
                return 'Please set-up a team first.';
            }

            return error.response.data.Message;
        } else if (error.response.status === 401) {
            return 'Please login first.';
        } else if (error.response.status === 413) {
            return 'The file was too large, please choose a smaller one and try again.';
        } else {
            const eventId = logError(error);

            return (
                'A server error occurred (Event-ID ' +
                eventId +
                '). Please try again or let us know if this issue persists.'
            );
        }
    } else if (error.request) {
        logError(error);

        if (error.message === 'Network Error') {
            return 'A connection error occurred. Please check your Internet connection and try again.';
        } else if (error.message === 'Request aborted') {
            return 'The request could not be completed, please check your network connection and try again.';
        } else {
            let message = 'A request error occurred';
            if (error.message) {
                message += ': ' + error.message;
            } else if (error.name) {
                message += ': ' + error.name;
            } else {
                message += '.';
            }

            return message;
        }
    } else {
        const eventId = logError(error);

        return (
            'A frontend error occurred (Event-ID ' +
            eventId +
            '). Please try again or let us know if this issue persists.'
        );
    }
}

export function isNotFoundError(e) {
    return e.response && e.response.status === 404;
}

export const handleError = (error) => {
    return (dispatch) => {
        if (error.response) {
            if ((error.response.status === 400 || error.response.status === 402) && error.response.data.Message) {
                if (error.response.data.Code === 'team_required') {
                    browserHistory.push('/team/create', {
                        targetPath: {
                            pathname: window.location.pathname,
                            search: window.location.search,
                        },
                    });

                    return;
                } else if (error.response.data.Code === 'boost_required') {
                    const data = error.response.data;

                    dispatch({
                        type: QUEUE_MESSAGE,
                        msg: (
                            <Box>
                                <div>{data.Message}</div>
                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                                    <BoostGate
                                        type={data.BoostedEntityType}
                                        featureId={data.FeatureID}
                                        requiredLevel={data.RequiredLevel}
                                        PopoverProps={{
                                            anchorOrigin: {
                                                horizontal: 'left',
                                                vertical: -40,
                                            },
                                            transformOrigin: {
                                                horizontal: 'left',
                                                vertical: 'bottom',
                                            },
                                            PaperProps: {
                                                sx: {
                                                    marginBottom: 10,
                                                },
                                            },
                                        }}
                                        hideBackdrop
                                    >
                                        <Button variant="contained" color="primary">
                                            Unlock Feature
                                        </Button>
                                    </BoostGate>
                                </Box>
                            </Box>
                        ),
                        variant: 'warning',
                        code: 'boost_required',
                        keepOpen: true,
                    });

                    return;
                }

                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: error.response.data.Message,
                    variant: 'error',
                    code: error.response.data.Code,
                });
            } else if (error.response.status === 401) {
                if (!window.location.pathname.startsWith('/play')) {
                    browserHistory.push('/login', {
                        targetPath: {
                            pathname: window.location.pathname,
                            search: window.location.search,
                        },
                    });
                }
            } else if (error.response.status === 413) {
                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: 'The file was too large, please choose a smaller one and try again.',
                    variant: 'error',
                    code: 'request_entity_too_large',
                });
            } else {
                const eventId = logError(error);

                dispatch({
                    type: QUEUE_MESSAGE,
                    msg:
                        'A server error occurred (Event-ID ' +
                        eventId +
                        '). Please try again or let us know if this issue persists.',
                    variant: 'error',
                    code: 'server-error',
                    actions: getReportActions(eventId),
                });
            }
        } else if (error.request) {
            const eventId = logError(error);

            if (error.message === 'Network Error') {
                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: 'A connection error occurred. Please check your Internet connection and try again.',
                    variant: 'error',
                    code: 'network_error',
                    actions: getReportActions(eventId),
                });
            } else if (error.message === 'Request aborted') {
                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: 'The request could not be completed, please check your network connection and try again.',
                    variant: 'error',
                    code: 'request_aborted_error',
                    actions: getReportActions(eventId),
                });
            } else {
                let message = 'A request error occurred';
                if (error.message) {
                    message += ': ' + error.message;
                } else if (error.name) {
                    message += ': ' + error.name;
                } else {
                    message += '.';
                }

                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: message,
                    variant: 'error',
                    code: 'generic_request_error',
                    actions: getReportActions(eventId),
                });
            }
        } else {
            const eventId = logError(error);

            dispatch({
                type: QUEUE_MESSAGE,
                msg:
                    'A frontend error occurred (Event-ID ' +
                    eventId +
                    '). Please try again or let us know if this issue persists.',
                variant: 'error',
                code: 'frontend-error',
                actions: getReportActions(eventId),
            });
        }
    };
};

export const hideMessage = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_MESSAGE,
        });
    };
};

export const flushQueue = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_MESSAGE,
        });
    };
};

export const deleteMessage = (id) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_MESSAGE,
            id: id,
        });
    };
};

import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PraccName } from 'containers/billing/StripePaymentPage';
import LogoImage from '../../containers/app/img/betalogo.png';
import { createStyles, makeStyles } from '@mui/styles';
import { RiSwordLine as SwordsIcon } from 'react-icons/ri';
import { SlCamrecorder as VideoCamIcon } from 'react-icons/sl';
import { SiProgress as OptimizeIcon } from 'react-icons/si';
import { FaRegPlayCircle as ReplayIcon } from 'react-icons/fa';
import { AiOutlineAim as DeathmatchIcon } from 'react-icons/ai';
import { IoNotifications } from 'react-icons/io5';
import { BsShieldLock, BsDiscord } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';
import LoggedInUserButton from './menu/logged-in-user-button';
import { IoMdSettings, IoIosAddCircle } from 'react-icons/io';
import TeamSelectionButton from '../../containers/app/menu/team-selection-button';
import { MdChat } from 'react-icons/md';
import {
    addNotification,
    loadProfile,
    openChat,
    openMsgs,
    resendConfirmationEmail,
    selectTeam,
    setEmailUnconfirmedHidden,
    setRatingIsOpenHidden,
    setUnreadMsgs,
    showNotifySnack,
    updateIsLoadingNotifications,
    updateProfile,
    updateTeamProfile,
} from './reducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import ChatDrawer from './chat/chat-drawer';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import NotificationDrawer from './NotificationDrawer';
import { BiBorderRadius } from 'react-icons/bi';
import { bindActionCreators } from 'redux';
import Slide from '@mui/material/Slide';
import Collapse from '@mui/material/Collapse';
import useUnreadChats from '../app/chat/useUnreadChats';
import { ButtonLink } from 'components/layout/buttons';
import { Dns, OndemandVideo as HighlightsIcon } from '@mui/icons-material';
import UserGameButton from './menu/user-game-button';
import { GAME_ID_CSGO } from '../../utils/games';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sideBarWrapper: {
            display: 'none',
            '@media (min-width: 1025px)': {
                height: '100vh',
                display: 'flex',
            },
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        activeLink: {
            backgroundColor: '#181D31',
            borderRadius: '10px',
        },
        logo: {
            zIndex: 10,
            position: 'relative',
            height: '74px',
            width: '77px',
            '@media (max-width: 1024px)': {
                height: '50px',
                width: '50px',
            },
        },
        listItem: {
            '& .MuiTypography-root': {
                fontSize: '1.1rem',
                fontWeight: 700,
            },
        },
        link: {
            color: 'white',
            padding: '0.3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            '&:hover': {
                backgroundColor: '#2c324a',
            },
        },
        notificationDrawer: {
            position: 'fixed',
            zIndex: 1000,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(12, 17, 38)',
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            height: '100%',
            marginLeft: '15rem',
            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
        },
    })
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

function SideMenu(props: any) {
    const classes = useStyles();
    const [showNotification, setShowNotification] = React.useState(false);
    // const [showChat, setShowChat] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {
        isLoadingNotifications,
        handleCloseDrawer,
        notifications,
        profile,
        selectTeam,
        msgDrawerOpen,
        activeChats,
    } = props;

    const { unreadChatsCount, isLoading, hasLoadError } = useUnreadChats();

    // Function to handle clicks outside of notification and chat drawers
    const handleOutsideClick = (event: MouseEvent) => {
        const notificationDrawer = document.getElementById('notificationDrawer');
        const chatDrawer = document.getElementById('chatDrawer');
        const emojiDrawer = document.getElementById('emojiDrawer');

        const isOutsideNotification = notificationDrawer && !notificationDrawer.contains(event.target as Node);
        const isOutsideChat = chatDrawer && !chatDrawer.contains(event.target as Node);
        const isOutsideEmoji = emojiDrawer && !emojiDrawer.contains(event.target as Node);

        if (isOutsideNotification) {
            setShowNotification(false);
        }

        if ((isOutsideChat && !emojiDrawer) || (isOutsideChat && isOutsideEmoji)) {
            openMsgs(false)(dispatch);
        }
    };

    // Add event listener for clicks outside of drawers
    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleMenuItemClick = (route: string) => {
        openMsgs(false)(dispatch);
        setShowNotification(false);
        history.push(route);
    };

    const isLinkActive = (routes: string[]): boolean => {
        return routes.some((route) => location.pathname === route || location.pathname.startsWith(`${route}/`));
    };

    const handleShowNotification = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        openMsgs(false)(dispatch);
        setShowNotification(!showNotification);
    };

    const handleShowChat = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setShowNotification(false);
        // setShowChat(!showChat);
        openMsgs(!msgDrawerOpen)(dispatch);
    };

    const hasTeam = profile?.Teams?.length > 0;
    const isCSGO = !profile || profile.GameId === GAME_ID_CSGO;

    const containerRef = React.useRef<HTMLElement>(null);

    return (
        <Box className={classes.sideBarWrapper} ref={containerRef}>
            <Box sx={{ display: 'flex' }}>
                <Drawer
                    variant="permanent"
                    open={true}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            backgroundColor: 'rgb(12, 17, 38)',
                            backgroundImage:
                                'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                        },
                        backgroundColor: 'rgb(12, 17, 38)',
                        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                    }}
                >
                    <DrawerHeader
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '1rem 1rem 0 1rem',
                            width: '100%',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#0d47a1',
                            cursor: 'pointer',
                            minHeight: '8.4rem',
                        }}
                        onClick={() => history.push(profile ? '/' : '/login')}
                    >
                        <img src={LogoImage} alt="PRACC.COM Logo" className={classes.logo} />

                        <PraccName style={{ textDecoration: 'none' }}>PRACC</PraccName>
                    </DrawerHeader>
                    <Divider />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingBottom: '0.5rem',
                            flex: 1,
                        }}
                    >
                        <List sx={{ width: '90%', paddingTop: '0.5rem' }}>
                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => handleMenuItemClick('/play')}
                                        className={isLinkActive(['/play']) ? classes.activeLink : ''}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <DeathmatchIcon size={25} />
                                        </ListItemIcon>
                                        <ListItemText primary="Play" className={classes.listItem} sx={{ opacity: 1 }} />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => handleMenuItemClick(hasTeam ? '/team/server' : '/team/create')}
                                        className={
                                            isLinkActive(['/team/server', '/team/create']) ? classes.activeLink : ''
                                        }
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Dns sx={{ fontSize: 25 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Server"
                                            className={classes.listItem}
                                            sx={{ opacity: 1 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => handleMenuItemClick('/highlights')}
                                        className={isLinkActive(['/highlights']) ? classes.activeLink : ''}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <HighlightsIcon sx={{ fontSize: 25 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Highlights"
                                            className={classes.listItem}
                                            sx={{ opacity: 1 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => handleMenuItemClick(profile ? '/search' : '/scrim-search')}
                                    className={
                                        isLinkActive(['/search', '/groups', '/matches', '/stats', '/scrim-search'])
                                            ? classes.activeLink
                                            : ''
                                    }
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SwordsIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText primary="Scrims" className={classes.listItem} sx={{ opacity: 1 }} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => handleMenuItemClick('/recorder')}
                                    className={isLinkActive(['/recorder']) ? classes.activeLink : ''}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <VideoCamIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText primary="Recorder" className={classes.listItem} sx={{ opacity: 1 }} />
                                </ListItemButton>
                            </ListItem>

                            {isCSGO && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            borderRadius: '10px',
                                        }}
                                        onClick={() => handleMenuItemClick(profile ? '/replay/matches' : '/replay')}
                                        className={isLinkActive(['/replay']) ? classes.activeLink : ''}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 3,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ReplayIcon size={25} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Replay"
                                            className={classes.listItem}
                                            sx={{ opacity: 1 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}

                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        borderRadius: '10px',
                                    }}
                                    onClick={() => handleMenuItemClick('/performance')}
                                    className={isLinkActive(['/performance']) ? classes.activeLink : ''}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <OptimizeIcon size={25} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Performance"
                                        className={classes.listItem}
                                        sx={{ opacity: 1 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>

                        <Divider variant="middle" sx={{ width: '90%' }} />

                        {profile ? (
                            <>
                                <List sx={{ width: '90%', paddingBottom: '0.5rem' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            backgroundColor: showNotification ? '#181D31' : 'none',
                                            borderRadius: '10px',
                                        }}
                                        onClick={(event: any) => handleShowNotification(event)}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: 'initial',
                                                px: 0,
                                                borderRadius: '10px',
                                                height: 51,
                                                py: 0,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: 6,
                                                    height: '100%',
                                                    backgroundColor: showNotification ? '#f50057' : '',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                }}
                                            />
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: 3,
                                                    justifyContent: 'center',
                                                    ml: 1.6,
                                                    position: 'relative',
                                                }}
                                            >
                                                <IoNotifications size={25} />
                                                {profile?.NewNotifications > 0 && (
                                                    <Box
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            backgroundColor: '#f50057',
                                                            borderRadius: '100%',
                                                            position: 'absolute',
                                                            right: '-0.2rem',
                                                            top: '-0.2rem',
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Notifications"
                                                className={classes.listItem}
                                                sx={{ opacity: 1 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        disablePadding
                                        sx={{
                                            display: 'block',
                                            backgroundColor: msgDrawerOpen ? '#181D31' : 'none',
                                            borderRadius: '10px',
                                        }}
                                        onClick={(event: any) => handleShowChat(event)}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: 'initial',
                                                px: 0,
                                                borderRadius: '10px',
                                                height: 51,
                                                py: 0,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: 6,
                                                    height: '100%',
                                                    backgroundColor: msgDrawerOpen ? '#f50057' : '',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                }}
                                            />
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: 3,
                                                    justifyContent: 'center',
                                                    ml: 1.6,
                                                    position: 'relative',
                                                }}
                                            >
                                                <MdChat size={25} />
                                                {unreadChatsCount > 0 && (
                                                    <Box
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            backgroundColor: '#f50057',
                                                            borderRadius: '100%',
                                                            position: 'absolute',
                                                            right: '-0.2rem',
                                                            top: '-0.2rem',
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Chats"
                                                className={classes.listItem}
                                                sx={{ opacity: 1 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>

                                <List sx={{ width: '90%', paddingTop: '0px', paddingBottom: '0.5rem' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            backgroundColor: '#181D31',
                                            width: 'auto',
                                            borderRadius: '10px',
                                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            paddingLeft: '1.25rem',
                                            paddingRight: '1.25rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: '#2c324a',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <LoggedInUserButton />
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                                {hasTeam ? (
                                    <List sx={{ width: '90%', paddingTop: '0px' }}>
                                        <ListItem
                                            disablePadding
                                            sx={{
                                                backgroundColor: '#181D31',
                                                width: 'auto',
                                                borderRadius: '10px',
                                                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                                paddingLeft: '1.25rem',
                                                paddingRight: '1.25rem',
                                                paddingTop: '0.3rem',
                                                paddingBottom: '0.5rem',
                                                height: 100,
                                                '&:hover': {
                                                    backgroundColor: '#2c324a',
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <TeamSelectionButton />

                                                <Box
                                                    style={{
                                                        backgroundColor: '#192841',
                                                        padding: '3px',
                                                        borderRadius: '10px',
                                                        marginTop: '1rem',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                        height: '34px',
                                                    }}
                                                    onClick={() => history.push('/team')}
                                                >
                                                    <IoMdSettings size={20} />
                                                    <Typography
                                                        sx={{
                                                            color: 'white',
                                                            fontSize: '1rem',
                                                            fontWeight: 700,
                                                            '&:hover': {
                                                                fontSize: '1.03rem',
                                                            },
                                                        }}
                                                    >
                                                        Manage Team
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </ListItem>
                                    </List>
                                ) : (
                                    <List sx={{ width: '90%', paddingTop: '0px' }}>
                                        <ListItem disablePadding sx={{ color: 'white', ' & > a': { color: 'white' } }}>
                                            <ButtonLink
                                                to="/team/create"
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                fullWidth
                                            >
                                                Create Team
                                            </ButtonLink>
                                        </ListItem>
                                    </List>
                                )}
                            </>
                        ) : (
                            <List sx={{ width: '90%' }}>
                                <ListItem disablePadding sx={{ color: 'white', ' & > a': { color: 'white' } }}>
                                    <ButtonLink to="/login" variant="contained" color="primary" size="large" fullWidth>
                                        Login
                                    </ButtonLink>
                                </ListItem>
                            </List>
                        )}
                        {!!profile && (
                            <div
                                style={{
                                    width: '100%',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    padding: 0,
                                }}
                            >
                                <List sx={{ width: '90%', paddingBottom: '0px', paddingTop: '0px' }}>
                                    <ListItem
                                        disablePadding
                                        sx={{
                                            backgroundColor: '#181D31',
                                            width: 'auto',
                                            borderRadius: '10px',
                                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            paddingLeft: '1.25rem',
                                            paddingRight: '1.25rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: '#2c324a',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <UserGameButton />
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        )}
                    </Box>

                    <div style={{ marginTop: 'auto' }}>
                        <Divider variant="middle" />

                        <div
                            style={{
                                padding: '1rem',
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '0.75rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <a
                                    className={classes.link}
                                    href={'https://discord.gg/SMKDMB94rx'}
                                    target={'_blank'}
                                    title={'Discord'}
                                >
                                    <BsDiscord size={25} />
                                </a>
                                <a
                                    className={classes.link}
                                    href={'https://twitter.com/praccgg'}
                                    target={'_blank'}
                                    title={'Twitter'}
                                >
                                    <FaXTwitter size={25} />
                                </a>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </Box>

            <Slide direction="right" container={containerRef.current} in={showNotification} mountOnEnter unmountOnExit>
                <div className={classes.notificationDrawer}>
                    <NotificationDrawer handleCloseDrawer={() => setShowNotification(false)} />
                </div>
            </Slide>

            <Slide direction="right" container={containerRef.current} in={msgDrawerOpen} mountOnEnter unmountOnExit>
                <div className={classes.notificationDrawer}>
                    <ChatDrawer />
                </div>
            </Slide>
        </Box>
    );
}

export default connect(
    (state: any) => ({
        profile: state.app.profile,
        isLoading: state.app.isLoading,
        isResendingConfirmationEmail: state.app.isResendingConfirmationEmail,
        isEmailUnconfirmedAlertHidden: state.app.isEmailUnconfirmedAlertHidden,
        isRatingOpenAlertHidden: state.app.isRatingOpenAlertHidden,
        msgDrawerOpen: state.app.msgDrawerOpen,
        notifications: state.app.notifications,
        websocket: state.app.websocket,
        isLoadingNotifications: state.app.isLoadingNotifications,
        activeChats: state.app.activeChats,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openChat,
                selectTeam,
                loadProfile,
                updateProfile,
                updateTeamProfile,
                resendConfirmationEmail,
                setEmailUnconfirmedHidden,
                openMsgs,
                showNotifySnack,
                addNotification,
                setUnreadMsgs,
                setRatingIsOpenHidden,
                updateIsLoadingNotifications,
            },
            dispatch
        )
)(SideMenu);

import React from 'react';
import { Box, Button, Collapse, DialogContent, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import DownloadHighlightRecorderDialog from '../highlights/DownloadHighlightRecorderDialog';
import { IoMdClose as CloseIcon, IoMdDownload as DownloadIcon } from 'react-icons/io';
import { FaRegPlayCircle as PlayIcon } from 'react-icons/fa';
import viewHighlight from './img/view-highlight.png';
import { browserHistory } from '../../store';
import { useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { PraccDialog } from '../../components/layout/dialogs';
import RouterLink from '../../components/RouterLink';

function HighlightsRecorderAlert() {
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const [isAlertOpen, setAlertOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const isMobileView = useMediaQuery('(max-width: 1024px)');
    const hasUsedApp = React.useMemo(
        () => (profile?.Apps.findIndex((app) => app.App === 'highlights-client') ?? -1) >= 0,
        [profile]
    );

    React.useEffect(() => {
        if (isMobileView) {
            return;
        }

        const timeout = setTimeout(() => {
            setAlertOpen(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [isMobileView]);

    React.useEffect(() => {
        if (hasUsedApp) {
            return;
        }

        const key = 'pracc_highlights_client_last_shown_at';
        const lastShownAt = localStorage.getItem(key);
        if (!lastShownAt || new Date().getTime() - new Date(lastShownAt).getTime() >= 3600 * 12 * 1000) {
            localStorage.setItem(key, new Date().toISOString());
            setDialogOpen(true);
        }
    }, [hasUsedApp]);

    return (
        <>
            <Collapse
                orientation="vertical"
                in={isAlertOpen}
                sx={{
                    width: '100%',
                    minHeight: 'auto !important',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    boxShadow: 5,
                }}
                collapsedSize={0}
                timeout={500}
                unmountOnExit
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        // Bright yellow to vibrant orange
                        background: 'linear-gradient(90deg, #FFD75D -40%, #FFA752 50%, #FF5C5C 100%)',
                        color: '#fff',
                        flexGrow: 1,
                        width: 'calc(100%)',
                        textAlign: 'center',
                        borderBottomRightRadius: 3,
                        borderBottomLeftRadius: 3,
                        marginTop: -1,
                        padding: 2,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}
                    onClick={() => browserHistory.push('/highlights')}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ position: 'relative', fontSize: 0 }}>
                            <Box sx={{ position: 'absolute', top: 'calc(50% - 14px)', left: 'calc(50% - 12.5px)' }}>
                                <PlayIcon size="25" />
                            </Box>
                            <img src={viewHighlight} height={40} alt="Highlight Example" />
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                color: 'darkred',
                                marginRight: 1,
                                marginLeft: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            NEW
                        </Box>{' '}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Automatically record highlights with our desktop application
                        </Box>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                                marginLeft: 1,
                                backgroundColor: '#0c1126',
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#0e1e43',
                                },
                            }}
                            onClick={() =>
                                browserHistory.push({
                                    pathname: 'highlights',
                                    search: 'downloadClient',
                                })
                            }
                        >
                            check it out
                        </Button>
                    </Box>
                    <IconButton size="small" sx={{ color: '#0c1126' }} onClick={(e) => {
                        e.stopPropagation();
                        setAlertOpen(false);
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Collapse>

            <PraccDialog open={dialogOpen} fullWidth maxWidth="md">
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{ marginLeft: 1, flexGrow: 1 }}>Automatically get highlights when you play</Box>
                    <IconButton size="small" onClick={(e) => setDialogOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        style={{ width: '100%', height: '40vh', maxHeight: 480 }}
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/bBqWagQK5qY?si=Mb1UWw-qEuImla-f"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Button
                            size="large"
                            variant="contained"
                            component={RouterLink}
                            to={{ pathname: 'highlights', search: 'downloadClient' }}
                            sx={{ width: '30%', height: 60 }}
                            onClick={() => setDialogOpen(false)}
                        >
                            Download client now!
                        </Button>
                    </Box>
                </DialogContent>
            </PraccDialog>
        </>
    );
}

export default HighlightsRecorderAlert;

import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ReactCountryFlag from 'react-country-flag';
import { usePing } from 'utils/usePing';
import { getAllSimpleServerLocations, getNetworkIconColor } from 'utils/server-locations';

interface SimpleServerLocationSelectProps extends SelectProps {}

let counter = 0;

function SimpleServerLocationSelect({ fullWidth, sx, label, ...rest }: SimpleServerLocationSelectProps) {
    // const sortedCities = usePing();
    const id = React.useMemo(() => `server-location-select-${counter++}`, []);
    const cities = getAllSimpleServerLocations()
    const renderSelectedValue = (value: string) => {
        const selectedCity = cities.find((city) => city.city === value);
        if (!selectedCity) return null;
        return (
            <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                {selectedCity.code && (
                <ReactCountryFlag
                    countryCode={selectedCity.code}
                    style={{ width: 20, height: 20 }}
                    svg
                    title={selectedCity.country}
                />
                )}
                <Typography sx={{ marginLeft: selectedCity.country != "All" ? 1 : 0, flexGrow: 1 }}>
                    {selectedCity.country}
                </Typography>
            </div>
        );
    };

    return (
        <FormControl fullWidth={fullWidth} sx={sx}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                label={label}
                {...rest}
                labelId={id}
                renderValue={(selected) => renderSelectedValue(selected as string)}
            >
                {cities.map((city) => (
                    <MenuItem value={city.city} key={city.city}>
                        <div
                            style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                display: 'flex',
                            }}
                        >
                            {city.code && (
                                <ReactCountryFlag
                                    countryCode={city.code}
                                    style={{ width: 20, height: 20 }}
                                    svg
                                    title={city.country}
                                />
                            )}
                            <Typography sx={{ marginLeft: city.country != "All" ? 1  : 0, flexGrow: 1 }}>
                                {city.country}
                            </Typography>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SimpleServerLocationSelect;

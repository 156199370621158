import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    Link,
} from '@mui/material';
// @ts-ignore
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { handleError, queueMessage } from '../../components/snackbar/reducer';
import axios from 'axios';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import { PraccDialog } from 'components/layout/dialogs';
import ExitLineIcon from '../../assets/lobbies/exit-line.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import KickIcon from '@mui/icons-material/HighlightOff';
import PlusIcon from '@mui/icons-material/AddCircleOutline';
import MinusIcon from '@mui/icons-material/RemoveCircleOutline';
import UserIcon from '../../assets/lobbies/user.svg';
import { SiFaceit } from 'react-icons/si';
import SteamIcon from '../../assets/lobbies/steam-icon.svg';
import MicEnabled from '@mui/icons-material/Mic';
import MicDisabled from '@mui/icons-material/MicOff';
import ShieldCheckEnabled from '@mui/icons-material/VerifiedUser';
import ShieldCheckDisabled from '@mui/icons-material/RemoveModerator';
import SlotUser from '../../assets/lobbies/slot-user.svg';
import AcceptIcon from '../../assets/lobbies/accept.svg';
import RejectIcon from '../../assets/lobbies/reject.svg';
import ExchangeIcon from '../../assets/lobbies/exchange.svg';
import csgoMaps from '../../utils/maps';
import { useLobbyUpdates } from './utils';
import { Lobby, LobbySlot } from '../../model/lobbies';
import { getAllServerLocations } from '../../utils/server-locations';
import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { browserHistory } from '../../store';
import clsx from 'clsx';
import { SettingsDialog } from './Lobbies';
import { orange } from '@mui/material/colors';
import green from '@mui/material/colors/green';
import SendIcon from '@mui/icons-material/Send';
import Fire from '@mui/icons-material/Whatshot';

interface Request {
    ID: number;
    Avatar: string;
    FaceItElo: number;
    COIN: number;
}

const requests: Request[] = [
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
];

const RequestCard = ({ request, isCreator }: { request: Request; isCreator: Boolean }) => {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                flex: 1,
                cursor: 'pointer',
                transition: 'background-size 0.2s ease-in-out',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage:
                    'linear-gradient(130deg, rgba(0, 212, 255, 0) 0%, rgba(13, 71, 161, 0.35) 30%, rgba(0, 212, 255, 0) 60%)',
                backgroundSize: '100% auto',
                '&:hover': {
                    backgroundSize: '250% auto',
                },
                height: '165px',
                width: 'calc(100% - 11px)',
                maxWidth: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '17px',
                    alignItems: 'center',
                    gap: '11px',
                }}
            >
                <Avatar
                    src={request.Avatar}
                    sx={{
                        height: 65,
                        width: 65,
                        borderRadius: '6px',
                    }}
                />

                <Box
                    sx={{
                        maxWidth: '80px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Box
                            sx={{
                                width: 15,
                                height: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SiFaceit color="#e4621b" size={10} />
                        </Box>
                        <Typography
                            sx={{
                                color: 'white',
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                                lineHeight: '11.72px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {request.FaceItElo ? `${request.FaceItElo} ELO` : '-'}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                        <Typography
                            sx={{
                                fontWeight: '600',
                                color: '#A7A7A7',
                                fontSize: '10px',
                                lineHeight: '11.72px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {`${request.COIN}`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '5px',
                        visibility: isCreator ? 'visible' : 'hidden',
                    }}
                >
                    <Button
                        sx={{
                            width: '35px',
                            height: '22px',
                            borderRadius: '3px',
                            minWidth: '35px',
                        }}
                        variant="contained"
                    >
                        <img src={AcceptIcon} style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button
                        sx={{
                            width: '35px',
                            height: '22px',
                            borderRadius: '3px',
                            minWidth: '35px',
                        }}
                        variant="outlined"
                    >
                        <img src={RejectIcon} style={{ width: '20px', height: '20px' }} />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const SwitchPlayerDialog = ({ open, onClose, lobby }: { open: boolean; onClose: () => void; lobby: Lobby }) => {
    const [selectedSlots, setSelectedSlots] = React.useState([-1, -1]);
    const [isWorking, setWorking] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (open) {
            setSelectedSlots([-1, -1]);
        }
    }, [open]);

    async function confirmChange() {
        let shouldClose = false;
        setWorking(true);
        try {
            if (selectedSlots.find((slot) => slot < 0)) {
                queueMessage('error', 'Select a slot from each team to perform the swap.')(dispatch);
            }

            await axios.post(`/api/play/lobbies/${lobby.Uid}/swap-slots`, {
                SourceSlotIndex: selectedSlots[0],
                TargetSlotIndex: selectedSlots[1],
            });

            queueMessage('success', 'Slots were swapped successfully.')(dispatch);
            shouldClose = true;
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);

        if (shouldClose) {
            onClose();
        }
    }

    return (
        <PraccDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>SWITCH PLAYERS</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {[0, 1].map((teamIndex) => {
                        return (
                            <Box key={teamIndex} sx={{ width: 250 }}>
                                <Typography>Team {teamIndex + 1}</Typography>

                                <Box sx={{ backgroundColor: '#0F162E', padding: 1, borderRadius: 1.5 }}>
                                    {lobby.Slots.map((slot, slotIndex) => {
                                        if (slot.Settings.Team !== teamIndex) {
                                            return null;
                                        }

                                        return (
                                            <Box
                                                className={clsx(selectedSlots[teamIndex] === slotIndex && 'selected')}
                                                sx={{
                                                    marginBottom: 1,
                                                    cursor: 'pointer',
                                                    '&:last-of-type': { marginBottom: 0 },
                                                    '&>div': {
                                                        border: '1px rgb(50, 50, 50) solid',
                                                    },
                                                    '&.selected': {
                                                        '&>div': {
                                                            borderColor: 'rgb(200, 200, 200)',
                                                        },
                                                    },
                                                    '&:hover': {
                                                        '&>div': {
                                                            borderColor: 'rgb(100, 100, 100)',
                                                        },
                                                    },
                                                }}
                                                onClick={() => {
                                                    setSelectedSlots((current) => {
                                                        const newSlots = [...current];
                                                        newSlots[teamIndex] = slotIndex;

                                                        return newSlots;
                                                    });
                                                }}
                                            >
                                                <LobbySlotDetails
                                                    key={slotIndex}
                                                    lobby={lobby}
                                                    slot={slot}
                                                    isHost={false}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        margin: '0 16px 20px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    <Button
                        onClick={confirmChange}
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{
                            color: '#333749',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                        disabled={isWorking}
                    >
                        CONFIRM
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                    >
                        CANCEL
                    </Button>
                </Box>
            </DialogActions>
        </PraccDialog>
    );
};

function LobbyTeamDetails({
    lobby,
    team,
    isHost,
    onChangePlayer,
}: {
    lobby: Lobby;
    team: number;
    isHost: boolean;
    onChangePlayer: () => void;
}) {
    const dispatch = useDispatch();
    const [isUpdating, setUpdating] = React.useState(false);
    const [averageFaceItElo, averageSteamElo] = React.useMemo(() => {
        let count = 0;
        let faceItElo = 0;
        let steamElo = 0;
        for (const slot of lobby.Slots) {
            if (slot.Settings.Team !== team) {
                continue;
            }
            if (!slot.User) {
                continue;
            }

            faceItElo += slot.User.FaceItElo;
            steamElo += slot.User.CsPlayMinutes;
            count++;
        }

        if (count === 0) {
            return [0, 0];
        }

        return [faceItElo, steamElo];
    }, [lobby, team]);

    async function updateTeamSlots(teamIndex: number, change: number) {
        const slotsPerTeam = [];
        for (const slot of lobby.Slots) {
            if (slot.Settings.Team + 1 > slotsPerTeam.length) {
                slotsPerTeam.push(0);
            }
        }
        for (const slot of lobby.Slots) {
            slotsPerTeam[slot.Settings.Team]++;
        }

        const newValue = slotsPerTeam[team] + change;
        if (newValue < 1 || newValue > 5) {
            return;
        }
        slotsPerTeam[team] = newValue;

        setUpdating(true);
        try {
            await axios.put(`/api/play/lobbies/${lobby.Uid}`, {
                Teams: slotsPerTeam,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
        setUpdating(false);
    }

    return (
        <Box
            sx={{
                flex: 1,
                borderRadius: '6px',
                borderColor: '#5D617080',
                borderStyle: 'solid',
                borderWidth: '1px',
                padding: 1,
                paddingTop: '20px',
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: '400',
                            fontSize: 15,
                            lineHeight: '17.58px',
                        }}
                    >
                        Team {team + 1}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2px',
                        }}
                    >
                        <Button
                            sx={{
                                width: '18px',
                                height: '18px',
                                minWidth: '18px',
                                visibility: isHost ? 'visible' : 'hidden',
                            }}
                            disabled={isUpdating}
                            onClick={() => updateTeamSlots(team, -1)}
                        >
                            <MinusIcon sx={{ width: 18, height: 18 }} />
                        </Button>
                        <Box
                            sx={{
                                width: '35px',
                                height: '25px',
                                borderRadius: '10px',
                                backgroundColor: '#242A41',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={UserIcon} style={{ width: '13px', height: '13px' }} />
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: '700',
                                    marginBottom: 0,
                                    lineHeight: '17.58px',
                                    marginTop: '2px',
                                }}
                            >
                                {lobby.Slots.reduce((value, slot) => value + (slot.Settings.Team === team ? 1 : 0), 0)}
                            </Typography>
                        </Box>
                        <Button
                            sx={{
                                width: '18px',
                                height: '18px',
                                minWidth: '18px',
                                visibility: isHost ? 'visible' : 'hidden',
                            }}
                            disabled={isUpdating}
                            onClick={() => updateTeamSlots(team, +1)}
                        >
                            <PlusIcon sx={{ width: 18, height: 18 }} />
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Box
                            sx={{
                                width: 15,
                                height: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SiFaceit color="#e4621b" size={10} />
                        </Box>
                        <Typography
                            sx={{
                                fontWeight: '600',
                                color: '#A7A7A7',
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {`${averageFaceItElo} ELO`}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                        <Typography
                            sx={{
                                fontWeight: '600',
                                color: '#A7A7A7',
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {`${Math.floor(averageSteamElo / 60)}h`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    gap: 1,
                    marginTop: '20px',
                }}
            >
                {lobby.Slots.filter((s) => s.Settings.Team === team).map((s) => (
                    <LobbySlotDetails lobby={lobby} slot={s} isHost={isHost} onChangePlayer={onChangePlayer} />
                ))}
            </Box>
        </Box>
    );
}

function LobbySlotDetails({
    lobby,
    slot,
    isHost,
    onChangePlayer,
}: {
    lobby: Lobby;
    slot: LobbySlot;
    isHost: boolean;
    onChangePlayer?: () => void;
}) {
    const dispatch = useDispatch();

    if (!slot.User) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    padding: '10px',
                    borderRadius: '5px',
                    borderWidth: 1,
                    borderStyle: 'dotted',
                    borderColor: '#5D617080',
                    gap: '20px',
                }}
            >
                <Box
                    sx={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '24px',
                        backgroundColor: '#192038',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '21px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: '#0C1227',
                        }}
                    >
                        <img src={SlotUser} style={{ width: 14, height: 20 }} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        color: '#A6ACC0',
                    }}
                >
                    <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>FREE</Typography>
                    <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>SLOT</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: '#192038',
                gap: '20px',
            }}
        >
            <Avatar sx={{ width: 48, height: 48 }} src={slot.User.Avatar} />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    marginBottom: 0,
                                    fontSize: '15px',
                                    fontWeight: '500',
                                    lineHeight: '17.58px',
                                }}
                            >
                                {slot.User.Nickname}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1px',
                                    marginLeft: '20px',
                                }}
                            >
                                <Tooltip title={slot.Ready ? 'READY' : 'NOT READY'} arrow placement="top">
                                    <Box
                                        sx={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '3px',
                                            backgroundColor: slot.Ready ? green[500] : orange[500],
                                        }}
                                    />
                                </Tooltip>
                                <MicDisabled sx={{ width: 14, height: 14 }} />
                                <ShieldCheckEnabled sx={{ width: 14, height: 14 }} />
                            </Box>
                        </Box>
                        {isHost && (
                            <Tooltip title="SWITCH PLAYERS" arrow placement="top">
                                <IconButton
                                    onClick={onChangePlayer}
                                    sx={{
                                        padding: 0,
                                        width: '20px',
                                        height: '20px',
                                    }}
                                >
                                    <img src={ExchangeIcon} style={{ width: '16px', height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Link
                            href={slot.User?.FaceItUrl?.replace('{lang}', 'en') || '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    '& .faceit-icon': {
                                        opacity: 0.8,
                                    },
                                },
                            }}
                        >
                            <Box
                                className="faceit-icon"
                                sx={{
                                    width: 15,
                                    height: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transition: 'opacity 0.2s',
                                }}
                            >
                                <SiFaceit color="#e4621b" size={10} />
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: '600',
                                    color: '#A7A7A7',
                                    fontSize: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {`${slot.User?.FaceItElo} ELO`}
                            </Typography>
                        </Link>
                        {isHost && (
                            <Tooltip title="REMOVE PLAYER" arrow placement="top">
                                <IconButton
                                    onClick={async () => {
                                        try {
                                            await axios.post(`/api/play/lobbies/${lobby.Uid}/kick/${slot.User?.ID}`);
                                        } catch (e) {
                                            handleError(e)(dispatch);
                                        }
                                    }}
                                    sx={{
                                        padding: 0,
                                        width: '20px',
                                        height: '20px',
                                    }}
                                >
                                    <KickIcon sx={{ 
                                        width: '19px', 
                                        height: '19px',
                                        color: '#3A86FA'
                                    }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Link
                            href={`https://steamcommunity.com/profiles/${slot.User.Steam64ID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                            <Typography
                                sx={{
                                    fontWeight: '600',
                                    color: '#A7A7A7',
                                    fontSize: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {`${Math.floor(slot.User.CsPlayMinutes / 60)}h`}
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function LobbyDetails() {
    const dispatch = useDispatch();
    const route = useRouteMatch();
    const [lobby, setLobby] = React.useState<Lobby | null>(null);
    const [isLoading, setLoading] = React.useState(true);
    const [openSwitchPlayerDialog, setOpenSwitchPlayerDialog] = React.useState(false);
    const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);

    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const requestSlider = React.useRef<Slider>(null);
    const map = React.useMemo(() => {
        return csgoMaps.find((m) => m.label === lobby?.Settings?.Map);
    }, [lobby?.Settings?.Map]);
    const location = React.useMemo(() => {
        return getAllServerLocations().find((l) => l.city === lobby?.Settings?.Location);
    }, [lobby?.Settings?.Location]);

    const [isResettingCode, setResettingCode] = React.useState(false);

    async function load() {
        try {
            const res = await axios.get(`/api/play/lobbies/${(route.params as any).id}`);
            setLobby(res.data);
            setLoading(false);
        } catch (e: any) {
            handleError(e)(dispatch);
        }
    }

    useLobbyUpdates((newLobby) => {
        if (newLobby.Uid === (route.params as any).id) {
            setLobby((current) => ({ ...current, ...newLobby }));
        }
    });

    React.useEffect(() => {
        load();
    }, []);

    if (isLoading) {
        return <CircularProgress size={60} color="primary" />;
    }
    if (!lobby) {
        return <Alert severity="error">Failed to load lobby data.</Alert>;
    }

    const isHost = profile?.ID === lobby.HostID;
    const ownSlot = lobby.Slots.find((slot) => slot.User?.ID === profile?.ID);

    return (
        <>
            <SettingsDialog
                open={openSettingsDialog}
                lobby={lobby}
                onConfirm={async (newSettings) => {
                    await axios.put(`/api/play/lobbies/${lobby.Uid}`, {
                        Settings: {
                            FriendlyFire: newSettings.friendlyFire,
                            Location: newSettings.location,
                            Map: newSettings.map,
                        },
                    });

                    queueMessage('success', 'The lobby settings were updated!')(dispatch);
                }}
                onClose={() => setOpenSettingsDialog(false)}
            />
            <Box
                sx={{
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#181D31',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '6px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px 5px',
                        }}
                    >
                        <PraccPaperHeader
                            sx={{ marginBottom: 0, fontSize: '30px', lineHeight: '35.16px', fontWeight: '500' }}
                        >
                            {lobby.Title}
                        </PraccPaperHeader>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <Button
                                sx={{
                                    width: '40px',
                                    height: '40px',
                                    borderWidth: 1,
                                    borderColor: '#5D6170',
                                    borderStyle: 'solid',
                                    borderRadius: '4px',
                                    minWidth: '40px',
                                    visibility: isHost ? 'visible' : 'hidden',
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(93, 97, 112, 0.1)',
                                    },
                                }}
                                onClick={() => setOpenSettingsDialog(true)}
                            >
                                <SettingsIcon sx={{ width: 20, height: 20, color: '#FFFFFF' }} />
                            </Button>
                            {isHost && (
                                <TextField
                                    label="Invite Link"
                                    fullWidth
                                    value={`${window.location.protocol}//${window.location.host}/i/${lobby.InviteCode}`}
                                    margin="dense"
                                    size="small"
                                    sx={{ marginTop: 0.5 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CopyToClipboardButton
                                                    text={`${window.location.protocol}//${window.location.host}/i/${lobby.InviteCode}`}
                                                />

                                                <Tooltip placement="top-end" title="generate new invite link">
                                                    <IconButton
                                                        disabled={isResettingCode}
                                                        size="small"
                                                        onClick={async () => {
                                                            setResettingCode(true);
                                                            try {
                                                                const res = await axios.put(
                                                                    `/api/play/lobbies/${
                                                                        lobby?.Uid ?? ''
                                                                    }/reset-invite-code`
                                                                );
                                                                setLobby((lobbyData) => ({
                                                                    ...(lobbyData as Lobby),
                                                                    InviteCode: res.data.InviteCode,
                                                                }));
                                                            } catch (e) {
                                                                handleError(e)(dispatch);
                                                            }
                                                            setResettingCode(false);
                                                        }}
                                                    >
                                                        <RefreshOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            <Button
                                sx={{
                                    width: '70px',
                                    height: '40px',
                                    borderWidth: 1,
                                    borderColor: '#F50157',
                                    borderStyle: 'solid',
                                    borderRadius: '4px',
                                    minWidth: '40px',
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(245, 1, 87, 0.1)',
                                    },
                                }}
                                onClick={async () => {
                                    try {
                                        await axios.post(`/api/play/lobbies/${lobby.Uid}/leave`);
                                        browserHistory.push('/play/lobbies');
                                    } catch (e) {
                                        handleError(e)(dispatch);
                                    }
                                }}
                            >
                                <img src={ExitLineIcon} style={{ width: '24px', height: '24px' }} />
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            minHeight: '494px',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                gap: 2,
                                backgroundColor: '#0F162E',
                                borderRadius: '6px',
                                padding: 2,
                            }}
                        >
                            <LobbyTeamDetails
                                lobby={lobby}
                                isHost={isHost}
                                team={0}
                                onChangePlayer={() => setOpenSwitchPlayerDialog(true)}
                            />
                            <Box
                                sx={{
                                    width: '190px',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundImage: `url(${map?.image})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        position: 'relative',
                                        width: '100%',
                                        height: '132px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '4px',
                                            backgroundImage: 'linear-gradient(#0E1D4000, #0A0D19E5)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                fontWeight: '500',
                                                fontSize: '22px',
                                                lineHeight: '25.78px',
                                                bottom: '16px',
                                                marginBottom: 0,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={map?.pin}
                                                width={25}
                                                height={25}
                                                alt=""
                                                style={{ flex: 1, alignItems: 'center', paddingRight: 4 }}
                                            />
                                            {map?.label}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 1,
                                        gap: '7px',
                                        backgroundColor: '#192038',
                                        borderRadius: '2px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: '300',
                                            fontSize: 13,
                                            lineHeight: '15.23px',
                                            color: '#5D6170',
                                        }}
                                    >
                                        Server:
                                    </Typography>
                                    <ReactCountryFlag
                                        countryCode={location?.code ?? 'EU'}
                                        style={{ width: 20, height: 20 }}
                                        svg
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            lineHeight: '15.23px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {location?.region}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        height: '28px',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: '23px',
                                            width: '1px',
                                            backgroundColor: '#181D33',
                                            marginTop: '5px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '2px',
                                        }}
                                    >
                                        {lobby.Settings.Voice ? (
                                            <MicEnabled sx={{ width: 20, height: 20 }} />
                                        ) : (
                                            <MicDisabled sx={{ width: 20, height: 20, color: "#A6ACC0" }} />
                                        )}
                                        <div style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}>
                                            Voice
                                            <br />
                                            {lobby.Settings.Voice ? 'On' : 'Off'}
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '23px',
                                            width: '1px',
                                            backgroundColor: '#181D33',
                                            marginTop: '5px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '2px',
                                        }}
                                    >
                                        {lobby.Settings.FriendlyFire ? (
                                            <Fire sx={{ width: 14, height: 16 }} />
                                        ) : (
                                            <Box sx={{ position: 'relative', width: 14, height: 16 }}>
                                                <Fire sx={{ width: 14, height: 16, color: "#A6ACC0" }} />
                                                <Box sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Box sx={{
                                                        width: '100%',
                                                        height: '2px',
                                                        backgroundColor: '#A6ACC0',
                                                        transform: 'rotate(45deg)',
                                                    }} />
                                                </Box>
                                            </Box>
                                        )}
                                        <div style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}>
                                            Friendly Fire
                                            <br />
                                            {lobby.Settings.FriendlyFire ? 'On' : 'Off'}
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '23px',
                                            width: '1px',
                                            backgroundColor: '#181D33',
                                            marginTop: '5px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '2px',
                                        }}
                                    >
                                        {lobby.Settings.AntiCheat ? (
                                            <ShieldCheckEnabled sx={{ width: 16, height: 16 }} />
                                        ) : (
                                            <ShieldCheckDisabled sx={{ width: 16, height: 16, color: "#A6ACC0" }} />
                                        )}
                                        <div style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}>
                                            Anti-Cheat
                                            <br />
                                            {lobby.Settings.AntiCheat ? 'On' : 'Off'}
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '23px',
                                            width: '1px',
                                            backgroundColor: '#181D33',
                                            marginTop: '5px',
                                        }}
                                    />
                                </Box>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        marginTop: '10px',
                                        visibility: isHost ? 'visible' : 'hidden',
                                    }}
                                    onClick={async () => {
                                        try {
                                            await axios.post(`/api/play/lobbies/${lobby?.Uid}/shuffle-teams`);
                                            queueMessage('success', 'Teams have been shuffled!')(dispatch);
                                        } catch (e) {
                                            handleError(e)(dispatch);
                                        }
                                    }}
                                >
                                    SHUFFLE TEAMS
                                </Button>
                                <Box
                                    sx={{
                                        marginTop: '55px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '185px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: '700',
                                                fontSize: 25,
                                                lineHeight: '29.3px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {lobby.Slots.reduce((current, slot) => current + (slot.Ready ? 1 : 0), 0)} /{' '}
                                            {lobby.Slots.length}
                                        </Typography>

                                        {ownSlot && (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: '40px',
                                                }}
                                                startIcon={
                                                    ownSlot.Ready ? (
                                                        <CheckBoxOutlined />
                                                    ) : (
                                                        <CheckBoxOutlineBlankOutlined />
                                                    )
                                                }
                                                onClick={async () => {
                                                    try {
                                                        await axios.post(`/api/play/lobbies/${lobby?.Uid}/ready`, {
                                                            Ready: !ownSlot?.Ready,
                                                        });
                                                    } catch (e) {
                                                        handleError(e)(dispatch);
                                                    }
                                                }}
                                            >
                                                READY
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Box>

                            <LobbyTeamDetails
                                lobby={lobby}
                                team={1}
                                isHost={isHost}
                                onChangePlayer={() => setOpenSwitchPlayerDialog(true)}
                            />

                            <SwitchPlayerDialog
                                lobby={lobby}
                                open={openSwitchPlayerDialog}
                                onClose={() => setOpenSwitchPlayerDialog(false)}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '304px',
                                backgroundColor: '#0F162E',
                                borderRadius: '6px',
                                padding: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    borderBottom: '1px solid #5D617040',
                                    paddingBottom: '16px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '4px',
                                        height: '24px',
                                        backgroundColor: '#F50157',
                                        borderRadius: '4px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        color: '#FFFFFF',
                                    }}
                                >
                                    Chat
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    padding: '24px 0 0 0',
                                    gap: '16px',
                                    height: '400px',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                        flex: 1,
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        paddingBottom: '76px',
                                        maxHeight: 'calc(100% - 60px)',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            background: 'transparent',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            background: '#5D617040',
                                            borderRadius: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb:hover': {
                                            background: '#5D617080',
                                        },
                                    }}
                                >
                                    {/* Beispiel-Nachrichten */}
                                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src="https://cdn.pracc.com/images/example1.png" />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>player1</Typography>
                                                <Typography sx={{ color: '#A7A7A7', fontSize: '12px' }}>14:23</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', color: '#A7A7A7' }}>
                                                gl & hf everyone!
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src="https://cdn.pracc.com/images/example2.png" />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#FF9F46' }}>crisby</Typography>
                                                <Typography sx={{ color: '#A7A7A7', fontSize: '12px' }}>14:24</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', color: '#A7A7A7' }}>
                                                Thanks! Let's discuss the strategy for the T side. I think we should focus on mid control and then decide between A or B based on the information we gather.
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src="https://cdn.pracc.com/images/example3.png" />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>player3</Typography>
                                                <Typography sx={{ color: '#A7A7A7', fontSize: '12px' }}>14:25</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', color: '#A7A7A7' }}>
                                                Sounds good!
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src="https://cdn.pracc.com/images/example3.png" />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>player3</Typography>
                                                <Typography sx={{ color: '#A7A7A7', fontSize: '12px' }}>14:25</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', color: '#A7A7A7' }}>
                                                Exciting content coming soon – stay tuned!
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src="https://cdn.pracc.com/images/example3.png" />
                                        <Box sx={{ flex: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>player3</Typography>
                                                <Typography sx={{ color: '#A7A7A7', fontSize: '12px' }}>14:25</Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', color: '#A7A7A7' }}>
                                                Work in progress, but something great is on the way.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                {/* Chat Input */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center',
                                        borderTop: '1px solid #5D617040',
                                        padding: '10px 0',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        placeholder="Type a message..."
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#242A41',
                                                '& fieldset': {
                                                    borderColor: '#5D617080',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#5D6170',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#5D6170',
                                                },
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                color: '#A7A7A7',
                                            },
                                        }}
                                    />
                                    <IconButton 
                                        sx={{ 
                                            backgroundColor: '#242A41',
                                            '&:hover': {
                                                backgroundColor: '#2A3149',
                                            },
                                        }}
                                    >
                                        <SendIcon sx={{ color: '#A7A7A7', width: 20, height: 20 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: '1px',
                            backgroundColor: '#5D617080',
                            marginTop: 2,
                            marginLeft: 2,
                            marginRight: 2,
                        }}
                    />
                    {lobby.Type === 'application' && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                padding: '20px',
                                paddingTop: '10px',
                                paddingRight: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <PraccPaperHeader
                                    sx={{ marginBottom: 0, fontSize: '18px', lineHeight: '31px', fontWeight: '500' }}
                                >
                                    JOIN REQUESTS
                                </PraccPaperHeader>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '10px',
                                    }}
                                >
                                    <Button
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: '#24315D',
                                            borderRadius: '2px',
                                            minWidth: '30px',
                                        }}
                                        disabled={true}
                                    >
                                        <ChevronLeft sx={{ width: '24px', height: '24px', color: '#ffffff29' }} />
                                    </Button>
                                    <Button
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: '#24315D',
                                            borderRadius: '2px',
                                            minWidth: '30px',
                                        }}
                                        disabled={true}
                                    >
                                        <ChevronRight sx={{ width: '24px', height: '24px', color: '#ffffff29' }} />
                                    </Button>
                                </Box>
                            </Box>
                            <>
                                <Slider
                                    ref={requestSlider}
                                    arrows={false}
                                    infinite={false}
                                    dots={false}
                                    slidesToShow={8}
                                    slidesToScroll={8}
                                >
                                    {requests.map((r, index) => (
                                        <RequestCard request={r} key={r.ID + `${index}`} isCreator={isHost} />
                                    ))}
                                </Slider>
                            </>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default LobbyDetails;
